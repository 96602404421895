import {
  Dialog,
  Disclosure,
  RadioGroup,
  Tab,
  Transition,
} from "@headlessui/react";
import React, { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shap1 from "../../assets/images/shap1.png";
import shap2 from "../../assets/images/shap2.png";
import shap3 from "../../assets/images/shap3.png";
import shap4 from "../../assets/images/shap4.png";
import shap5 from "../../assets/images/shap5.png";
import shap6 from "../../assets/images/shap6.png";
import shap7 from "../../assets/images/shap7.png";
import shap8 from "../../assets/images/shap8.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttributeValueData } from "../../store/Slices/attributeValueSlice";
import { getSelectedDiamondState } from "../../store/Slices/diamondSelectSlice";
import {
  GetDesignCatalog,
  designsVariationSearch,
} from "../../Services/designService";
import Header from "../LandingComponent/Header";
import classNames from "classnames";
import { HeartIcon, MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import {
  fetchDesigns,
  getDesignDataState,
} from "../../store/Slices/designSlice";
import ProductCatalogSkeleton from "../LandingComponent/Common/skeleton/ProductCatalogSkeleton";

// const dataDemo = [
//   {
//     unDesignIdentifier: "99a0501a-4a0e-4522-bcf7-e48dc2a3e8e1",
//     stDesignName: "Princess Solitaire Diamond Ring",
//     stDesignAlias: "3003RG",
//     stRemark1: "Princess Solitaire Diamond Ring in White Gold with half pave",
//     stRemark2: "",
//     unMetalId: "98d19b68-5cdc-429e-924c-e6ddd92c86e7",
//     stDefMainStoneShape: "98d19df3-cc9d-4b12-89de-353ca25e289c",
//     dcWaxWt: "1.000",
//     dcNtWt: "1.000",
//     dcGrWt: "1.000",
//     dcSalePrice: "1500.00",
//     dcCostWastePercentage: "0.00",
//     dcCostCADCAM: "0.00",
//     dcCostLabour: "0.00",
//     dcCostSetting: "0.00",
//     dcCostOther: "0.00",
//     dcTotalCost: "1000.00",
//     inShippingDays: "7",
//     unAccountId: "98cc1762-3ae3-4183-a255-9887017dc597",
//     flgIsNew: "1",
//     flgIsHotDesign: "0",
//     flgIsPublish: "0",
//     flgIsActive: "1",
//     Description1:
//       "<p><strong>Details</strong></p><p>&nbsp;</p><p>Princess Solitaire Diamond Ring in White Gold with half pave…</p><p>&nbsp;</p><p>Main Diamond &nbsp; &nbsp; : &nbsp;<strong>3.3crt D VVS2</strong></p><p>Metal Name &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;<strong>18K White Gold</strong></p><p>Band width &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :<strong> &nbsp;2 mm</strong></p><p>Band Height &nbsp; &nbsp; &nbsp; &nbsp; :<strong> &nbsp;1.6mm</strong></p><p>&nbsp;</p>",
//     Description2: "",
//     stDefImageURL:
//       "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689153807/tzc1l3977hedtw9gv3ud.jpg",
//     stFocusImageURL:
//       "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689153814/ltpormkrcau0i1jfpuen.jpg",
//     stVideoUrl: null,
//     category: "RING",
//     isSize: "1",
//     isEngraving: "1",
//     categoryStyle: "Solitaire",
//     designer: "demo1",
//     metal: "18KT White Gold",
//     inNoOfMainStone: 0,
//     designAttribute: [
//       {
//         unDesignAttributeIdentifier: "99a1847f-918b-404f-b35d-8b7edfd33b86",
//         unAttributeId: "98c2306a-03a9-48c8-8c99-118d9905d828",
//         stAttributeName: "Shape",
//         inSortOrder: "1",
//       },
//       {
//         unDesignAttributeIdentifier: "99a1847f-9218-47e6-bf35-783bb563c7de",
//         unAttributeId: "98c2301f-8dad-4b0a-8eac-d15cedf75097",
//         stAttributeName: "Metal",
//         inSortOrder: "2",
//       },
//       {
//         unDesignAttributeIdentifier: "99a1847f-92bd-41bb-9781-f7e975ca357f",
//         unAttributeId: "98d19f53-4580-4506-b254-63983887cb3e",
//         stAttributeName: "Band",
//         inSortOrder: "3",
//       },
//     ],
//     designAttributeValue: [
//       {
//         unDesignAttributeIdentifier: "99a0ad60-9596-4621-bc20-c8be59b07fba",
//         unAttributeId: "98c2306a-03a9-48c8-8c99-118d9905d828",
//         unAttributeValueId: "98d19c2e-23d3-4988-86f0-6fda397532fe",
//         stAttributeName: "Shape",
//         stAttributeValueName: "ROUND",
//         stAttributeValueAlias: "BR",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1686995655/fiqqpre8xpkbjqum5erp.png",
//         inSortOrder: "1",
//       },
//       {
//         unDesignAttributeIdentifier: "99a0ad60-9704-465c-b48a-fe1dadbdd559",
//         unAttributeId: "98c2306a-03a9-48c8-8c99-118d9905d828",
//         unAttributeValueId: "98d19df3-cc9d-4b12-89de-353ca25e289c",
//         stAttributeName: "Shape",
//         stAttributeValueName: "PRINCESS",
//         stAttributeValueAlias: "PR",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1686997391/vx3kmc0j2p38rhp1rhxq.png",
//         inSortOrder: "2",
//       },
//       {
//         unDesignAttributeIdentifier: "99a0ad60-9778-4dbf-84ac-19d68c504814",
//         unAttributeId: "98c2301f-8dad-4b0a-8eac-d15cedf75097",
//         unAttributeValueId: "98d19b68-5cdc-429e-924c-e6ddd92c86e7",
//         stAttributeName: "Metal",
//         stAttributeValueName: "18KT White Gold",
//         stAttributeValueAlias: "WG",
//         stImageURL: "",
//         inSortOrder: "3",
//       },
//       {
//         unDesignAttributeIdentifier: "99a0ad60-97e2-46d1-928a-87a78921652e",
//         unAttributeId: "98d19f53-4580-4506-b254-63983887cb3e",
//         unAttributeValueId: "98d1a213-b7f3-4c83-bfdd-beaff68cfea9",
//         stAttributeName: "Band",
//         stAttributeValueName: "Pavé",
//         stAttributeValueAlias: "PV",
//         stImageURL: "",
//         inSortOrder: "4",
//       },
//       {
//         unDesignAttributeIdentifier: "99a0ad60-9854-4ee5-a01a-c2bc202cbaff",
//         unAttributeId: "98d19f53-4580-4506-b254-63983887cb3e",
//         unAttributeValueId: "99a07a58-0283-4ea1-9484-568e281fcc25",
//         stAttributeName: "Band",
//         stAttributeValueName: "Half Pavé",
//         stAttributeValueAlias: "Half Pavé",
//         stImageURL: "",
//         inSortOrder: "5",
//       },
//     ],
//     designVariation: [],
//     designTag: [
//       {
//         unTagIdentifier: "98d3848a-03c3-4918-b8e3-148a1289a1ec",
//         stTagName: "Gift",
//         stImageURL:
//           "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
//         flgIsActive: "1",
//       },
//     ],
//     designImages: [
//       {
//         unDesignId: "99a0501a-4a0e-4522-bcf7-e48dc2a3e8e1",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689137781/lkllqlopcvz3itponzbw.jpg",
//         inSortOrder: "1",
//       },
//       {
//         unDesignId: "99a0501a-4a0e-4522-bcf7-e48dc2a3e8e1",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689137782/zajgl4d6vc9pbddfxen6.jpg",
//         inSortOrder: "2",
//       },
//       {
//         unDesignId: "99a0501a-4a0e-4522-bcf7-e48dc2a3e8e1",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689137783/qnomtrtrsbxmxlwqw2uq.jpg",
//         inSortOrder: "3",
//       },
//     ],
//     designMetal: [],
//     designSideStone: [],
//   },
//   {
//     unDesignIdentifier: "99a09ddb-4a73-4357-b024-cf66f875b89d",
//     stDesignName: "Eternity Diamond Ring",
//     stDesignAlias: "3011RG",
//     stRemark1: "Pave Eternity Diamond Ring",
//     stRemark2: "",
//     unMetalId: "98d19b47-2f38-4f57-8a95-ff976103c851",
//     stDefMainStoneShape: "98d19c2e-23d3-4988-86f0-6fda397532fe",
//     dcWaxWt: "1.000",
//     dcNtWt: "1.000",
//     dcGrWt: "1.000",
//     dcSalePrice: "4900.00",
//     dcCostWastePercentage: "0.00",
//     dcCostCADCAM: "0.00",
//     dcCostLabour: "0.00",
//     dcCostSetting: "0.00",
//     dcCostOther: "0.00",
//     dcTotalCost: "1.00",
//     inShippingDays: "7",
//     unAccountId: "98cc1762-3ae3-4183-a255-9887017dc597",
//     flgIsNew: "1",
//     flgIsHotDesign: "0",
//     flgIsPublish: "0",
//     flgIsActive: "1",
//     Description1:
//       "<p><strong>Details</strong></p><p>&nbsp;</p><p>Pave Eternity Diamond Ring</p>",
//     Description2: "",
//     stDefImageURL:
//       "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689151030/tbouh9tlku6aslzireyp.png",
//     stFocusImageURL:
//       "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689151033/nnlfm3dpsml5k5atco2b.png",
//     stVideoUrl: null,
//     category: "RING",
//     isSize: "1",
//     isEngraving: "1",
//     categoryStyle: "Eternity",
//     designer: "demo1",
//     metal: "18KT Yellow Gold",
//     inNoOfMainStone: 0,
//     designAttribute: [
//       {
//         unDesignAttributeIdentifier: "99a09ddb-4e2e-45ac-832b-1e160f3e7c5d",
//         unAttributeId: "98c2306a-03a9-48c8-8c99-118d9905d828",
//         stAttributeName: "Shape",
//         inSortOrder: "1",
//       },
//       {
//         unDesignAttributeIdentifier: "99a09ddb-4ecc-4e50-b33b-f0b97e2de3ae",
//         unAttributeId: "98c2301f-8dad-4b0a-8eac-d15cedf75097",
//         stAttributeName: "Metal",
//         inSortOrder: "2",
//       },
//       {
//         unDesignAttributeIdentifier: "99a09ddb-501c-4d5a-9d6b-11469f0d9e46",
//         unAttributeId: "98d19f53-4580-4506-b254-63983887cb3e",
//         stAttributeName: "Band",
//         inSortOrder: "3",
//       },
//     ],
//     designAttributeValue: [
//       {
//         unDesignAttributeIdentifier: "99a09e12-cfdd-4518-b146-908fec8f4cca",
//         unAttributeId: "98c2301f-8dad-4b0a-8eac-d15cedf75097",
//         unAttributeValueId: "98d19b47-2f38-4f57-8a95-ff976103c851",
//         stAttributeName: "Metal",
//         stAttributeValueName: "18KT Yellow Gold",
//         stAttributeValueAlias: "YG",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1680887944/dkqisevqehmarh5mqrnt.jpg",
//         inSortOrder: "1",
//       },
//       {
//         unDesignAttributeIdentifier: "99a09e12-d08d-43ca-a5eb-ae11ca05f948",
//         unAttributeId: "98c2301f-8dad-4b0a-8eac-d15cedf75097",
//         unAttributeValueId: "98e99b69-566f-47c3-9d68-130fe184fa1a",
//         stAttributeName: "Metal",
//         stAttributeValueName: "14KT Yellow Gold",
//         stAttributeValueAlias: "14KT YG",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1681291017/ubdxkfavynzuy520p30d.png",
//         inSortOrder: "2",
//       },
//       {
//         unDesignAttributeIdentifier: "99a09e12-d0ed-48af-a904-8ae80892a3c2",
//         unAttributeId: "98c2306a-03a9-48c8-8c99-118d9905d828",
//         unAttributeValueId: "98d19c2e-23d3-4988-86f0-6fda397532fe",
//         stAttributeName: "Shape",
//         stAttributeValueName: "ROUND",
//         stAttributeValueAlias: "BR",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1686995655/fiqqpre8xpkbjqum5erp.png",
//         inSortOrder: "3",
//       },
//       {
//         unDesignAttributeIdentifier: "99a09e12-d176-47b0-ae74-dd9064243836",
//         unAttributeId: "98c2306a-03a9-48c8-8c99-118d9905d828",
//         unAttributeValueId: "991278fc-6850-46aa-bbcd-de231a36f60e",
//         stAttributeName: "Shape",
//         stAttributeValueName: "BAGUETTE",
//         stAttributeValueAlias: "Baguette",
//         stImageURL: "",
//         inSortOrder: "4",
//       },
//       {
//         unDesignAttributeIdentifier: "99a09e12-d1dd-4720-bc2b-e89809a8f092",
//         unAttributeId: "98d19f53-4580-4506-b254-63983887cb3e",
//         unAttributeValueId: "98d1a213-b7f3-4c83-bfdd-beaff68cfea9",
//         stAttributeName: "Band",
//         stAttributeValueName: "Pavé",
//         stAttributeValueAlias: "PV",
//         stImageURL: "",
//         inSortOrder: "5",
//       },
//     ],
//     designVariation: [],
//     designTag: [
//       {
//         unTagIdentifier: "98d3848a-03c3-4918-b8e3-148a1289a1ec",
//         stTagName: "Gift",
//         stImageURL:
//           "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
//         flgIsActive: "1",
//       },
//     ],
//     designImages: [
//       {
//         unDesignId: "99a09ddb-4a73-4357-b024-cf66f875b89d",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689151164/qtqvewhtfavcnq4fwa93.png",
//         inSortOrder: "1",
//       },
//       {
//         unDesignId: "99a09ddb-4a73-4357-b024-cf66f875b89d",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689151165/jdyuwsnr8syq9ryk6aq2.png",
//         inSortOrder: "2",
//       },
//       {
//         unDesignId: "99a09ddb-4a73-4357-b024-cf66f875b89d",
//         stImageURL:
//           "https://res.cloudinary.com/dgfofaqxf/image/upload/v1689151166/g28gpekmdoh4p2oo9fef.png",
//         inSortOrder: "3",
//       },
//     ],
//     designMetal: [],
//     designSideStone: [],
//   },
// ];

const ECatalog = () => {
  const [openData, setOpenData] = useState(0);
  const [data, setData] = useState(null);
  const [dataDemo, setDataDemo] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [mainImage, setMainImage] = useState();
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const { data: Design } = useSelector(getDesignDataState);
  const { data: selectedDiamond } = useSelector(getSelectedDiamondState);
  const { id } = useParams();

  useEffect(() => {
    if (dataDemo.length > 0) {
      setMainImage(dataDemo[openData].stDefImageURL);
    }
  }, [dataDemo, openData]);

  useEffect(() => {
    dispatch(fetchDesigns());
  }, [dispatch]);

  const GetCatalogData = useMemo(() => {
    return async (id) => {
      const res = await GetDesignCatalog(id);
      if (res.status === 200) {
        const Ids = res.data.data.design_ids;
        const CatalogData = await Design.flatMap((item) => {
          let arr = [];
          Ids.map((ite) => {
            if (item.unDesignIdentifier === ite) {
              arr.push(item);
            }
          });
          return arr;
        });
        setDataDemo(CatalogData);
      }
    };
  }, [Design]);

  useEffect(() => {
    if (id) {
      GetCatalogData(id);
    }
  }, [id, GetCatalogData]);

  useEffect(() => {
    dispatch(fetchAttributeValueData());
  }, [dispatch]);

  const images = useMemo(() => {
    if (dataDemo[openData]) {
      const productImage = dataDemo[openData].designImages.map(
        (item) => item.stImageURL
      );
      // if (selectedVariation) {
      //   return [
      //     ...selectedVariation.design_variation_images,
      //     ...productImage,
      //     dataDemo[openData].stDefImageURL,
      //     dataDemo[openData].stFocusImageURL,
      //   ];
      // } else {
      // }
      setLoad(false);
      return [
        dataDemo[openData].stDefImageURL,
        dataDemo[openData].stFocusImageURL,
        ...productImage,
      ];
    }
    return [];
  }, [dataDemo, openData]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const productImageSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    arrows: false,
  };

  const handleChangeShape = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const handleChangeMetal = (name, value, valId) => {
    setData((prev) => ({ ...prev, [name]: { name: value, id: valId } }));
  };

  const productPrice = useMemo(() => {
    if (selectedVariation) {
      return (
        parseInt(selectedVariation?.dcSalePrice) +
        parseInt(selectedVariation?.dcVariationPrice) ||
        parseInt(dataDemo[openData]?.dcSalePrice)
      );
    }
    return parseInt(dataDemo[openData]?.dcSalePrice) || 0;
  }, [selectedVariation, dataDemo[openData]]);

  const stonePrice = useMemo(() => {
    if (selectedDiamond?.length) {
      return selectedDiamond.reduce(
        (acc, cur) => acc + parseInt(cur.Amount),
        0
      );
    }
    return 0;
  }, [selectedDiamond]);

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  return (
    <>
      <div className="bg-[#f8fafc] md:h-screen h-auto pb-4 flex items-center">
        <div className="xl:max-w-[1224px] max-w-auto w-full xl:px-0 px-3 mx-auto mt-4s">
          <div className="flex items-center justify-between sm:gap-2 pt-4 mb-[14px]">
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  w-auto disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                setOpenData(openData - 1);
                setData(null);
              }}
              disabled={openData === 0}
            >
              <CgArrowLongLeft />
            </button>
            <h1 className="sm:text-[26px] text-[21px] font-semibold">
              DTN{" "}
              <span className="font-dancingScript font-bold">E-Catalog</span>{" "}
              Design
            </h1>
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-[#334155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#64748b] sm:ml-3 w-auto disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                setOpenData(openData + 1);
                setData(null);
              }}
              disabled={openData === dataDemo.length - 1}
            >
              <CgArrowLongRight />
            </button>
          </div>
          {load ? (
            <ProductCatalogSkeleton />
          ) : (
            <div className="bg-white shadow-md p-4 rounded">
              <div className="mx-auto max-w-2xl lg:max-w-7xl">
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                  {/* Image gallery */}
                  <Tab.Group
                    as="div"
                    className="sm:flex flex-col-reverse hidden"
                  >
                    {/* Image selector */}
                    <div className="mx-auto mt-6 w-full max-w-2xl lg:max-w-none">
                      <Tab.List className={`grid sliderGrid gap-6`}>
                        {images.slice(0, 5).map((image) => (
                          <Tab
                            key={image.id}
                            className="relative col-span-1 flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
                          >
                            {({ selected }) => (
                              <>
                                <span className="sr-only">{image.name}</span>
                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                  <img
                                    src={image}
                                    alt=""
                                    className="h-full w-full object-cover object-center"
                                  />
                                </span>
                                <span
                                  className={classNames(
                                    selected
                                      ? "ring-[#64748b]"
                                      : "ring-[#e2e8f0]",
                                    "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2 outline-none"
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>

                    <Tab.Panels className="aspect-h-1 aspect-w-1 w-full ring-1 ring-[#e2e8f0] rounded">
                      {images.map((image) => (
                        <Tab.Panel key={image}>
                          <img
                            src={image}
                            alt={image}
                            className="sm:h-[470px] h-full w-full sm:w-[580px] object-cover object-center sm:rounded-lg"
                          />
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>

                  <div className="sm:hidden block ">
                    <Slider
                      {...productImageSettings}
                      className="details_page max-w-[100%] w-[325px] h-[338px] mx-auto flex items-center shadow-lg"
                    >
                      {images.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="details__image max-h-[330px] overflow-hidden"
                          >
                            <img
                              src={item}
                              alt="Product"
                              className="h-full w-full object-cover object-center sm:rounded-lg"
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  {/* Product info */}
                  <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                      {dataDemo.length > 0 && dataDemo[openData].stDesignName}
                    </h1>
                    <p className="lg:text-[16px] text-[14px] lg:leading-[24px] leading-[20px] text-[#000] font-[300] my-3">
                      {dataDemo[openData]?.stRemark1}
                    </p>
                    <div className="mb-3">
                      <h2 className="sr-only">Product information</h2>
                      <p className="text-xl tracking-tight text-gray-900">
                        Starting at ${(productPrice + stonePrice).toFixed(2)}
                      </p>
                    </div>

                    <div className="mb-9">
                      {dataDemo[openData]?.designAttribute.length &&
                        dataDemo[openData]?.designAttribute.map((item, i) => {
                          if (item.stAttributeName === "Shape") {
                            const ShapeData = dataDemo[
                              openData
                            ]?.designAttributeValue.filter(
                              (itm) =>
                                itm.stAttributeName === item.stAttributeName
                            );
                            return (
                              <div className="mb-4">
                                <h3 className="text-[16px] leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px]">
                                  {item.stAttributeName} :{" "}
                                  <span className="font-[300]">
                                    {data?.Shape?.name}
                                  </span>
                                </h3>
                                {ShapeData.length >= 7 ? (
                                  <Slider {...settings}>
                                    {ShapeData.length > 0 &&
                                      ShapeData.map((itm, i) => {
                                        return (
                                          <button
                                            key={i}
                                            type="button"
                                            onClick={handleChangeShape.bind(
                                              null,
                                              item.stAttributeName,
                                              itm.stAttributeValueName,
                                              itm?.unAttributeValueId
                                            )}
                                            className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${data?.Shape?.name ===
                                              itm.stAttributeValueName
                                              ? "bg-[#f1f5f9]"
                                              : ""
                                              }`}
                                          >
                                            <img
                                              src={shapeImages(
                                                itm.stAttributeValueName
                                              )}
                                              alt={`shape-${i + 1}`}
                                              className="m-auto h-[30px]"
                                            />
                                          </button>
                                        );
                                      })}
                                  </Slider>
                                ) : (
                                  <div className="flex items-center gap-2">
                                    {ShapeData.length > 0 &&
                                      ShapeData.map((itm, i) => {
                                        return (
                                          <button
                                            key={i}
                                            type="button"
                                            onClick={handleChangeShape.bind(
                                              null,
                                              item.stAttributeName,
                                              itm.stAttributeValueName,
                                              itm?.unAttributeValueId
                                            )}
                                            className={`py-2 px-2 w-[50px] h-[50px] border-2 rounded shadow-lg border-[#cbd5e1] ${data?.Shape?.name ===
                                              itm.stAttributeValueName
                                              ? "bg-[#f1f5f9]"
                                              : ""
                                              }`}
                                          >
                                            <img
                                              src={shapeImages(
                                                itm.stAttributeValueName
                                              )}
                                              alt={`shape-${i + 1}`}
                                              className="m-auto h-[30px]"
                                            />
                                          </button>
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                            );
                          }
                          if (item.stAttributeName === "Metal") {
                            const MetalData = dataDemo[
                              openData
                            ]?.designAttributeValue.filter(
                              (it) =>
                                it.stAttributeName === item.stAttributeName
                            );
                            return (
                              <div className="">
                                <h3 className="text-[16px] leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px]">
                                  {item.stAttributeName} :{" "}
                                  <span className="font-[300]">
                                    {data?.[item.stAttributeName]?.name}
                                  </span>
                                </h3>
                                <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
                                  {MetalData?.length > 0 &&
                                    MetalData.map((it) => {
                                      return (
                                        <button
                                          type="button"
                                          onClick={handleChangeMetal.bind(
                                            null,
                                            item.stAttributeName,
                                            it.stAttributeValueName,
                                            it.unAttributeValueId
                                          )}
                                          className={`border-4 px-2 py-1 ${data?.[item.stAttributeName]
                                            ?.name ===
                                            it.stAttributeValueName &&
                                            it.stAttributeValueName?.includes(
                                              "White Gold"
                                            )
                                            ? "bg-[#e4e5e2]"
                                            : data?.[item.stAttributeName]
                                              ?.name ===
                                              it.stAttributeValueName &&
                                              it.stAttributeValueName?.includes(
                                                "Rose Gold"
                                              )
                                              ? "bg-[#e6be8a]"
                                              : data?.[item.stAttributeName]
                                                ?.name ===
                                                it.stAttributeValueName &&
                                                it.stAttributeValueName?.includes(
                                                  "Yellow Gold"
                                                )
                                                ? "bg-[#d4af37]"
                                                : data?.[item.stAttributeName]
                                                  ?.name ===
                                                  it.stAttributeValueName &&
                                                  it.stAttributeValueName?.includes(
                                                    "Yellow Gold"
                                                  )
                                                  ? "bg-[#e5e4e2]"
                                                  : "bg-[#fff]"
                                            }
                                  }  rounded-full ${it.stAttributeValueName?.includes(
                                              "White Gold"
                                            )
                                              ? "border-[#e4e5e2]"
                                              : it.stAttributeValueName?.includes(
                                                "Rose Gold"
                                              )
                                                ? "border-[#e6be8a]"
                                                : it.stAttributeValueName?.includes(
                                                  "Yellow Gold"
                                                )
                                                  ? "border-[#d4af37]"
                                                  : "border-[#e5e4e2]"
                                            } font-semibold text-[14px]`}
                                        >
                                          {it.stAttributeValueName[0] +
                                            it.stAttributeValueName[1]}
                                        </button>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          }
                          const attrValData = dataDemo[
                            openData
                          ].designAttributeValue.filter(
                            (attrVal) =>
                              attrVal.unAttributeId === item.unAttributeId
                          );
                          return (
                            <div className="mb-4">
                              <h3 className="text-[16px] leading-[36px] font-[500] font-Josefin text-[#000] mb-[4px]">
                                {item.stAttributeName} :{" "}
                                <span className="font-[300]">
                                  {data?.[item.stAttributeName]?.name}
                                </span>
                              </h3>
                              <div className="flex items-center gap-2">
                                {attrValData?.length > 0 &&
                                  attrValData?.map((band) => {
                                    return (
                                      <button
                                        key={band.unAttributeValueId}
                                        type="button"
                                        onClick={handleChangeMetal.bind(
                                          null,
                                          item.stAttributeName,
                                          band.stAttributeValueName,
                                          band.unAttributeValueId
                                        )}
                                        className={`border px-3 ${data?.[item.stAttributeName]?.name ===
                                          band.stAttributeValueName
                                          ? "bg-[#f1f5f9]"
                                          : ""
                                          } rounded p-1`}
                                      >
                                        {band.stAttributeValueName}
                                      </button>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ECatalog;
