import React, { useEffect } from "react";
import { useState } from "react";
import shap1 from "../../assets/images/shap1.png";
import shap2 from "../../assets/images/shap2.png";
import shap3 from "../../assets/images/shap3.png";
import shap4 from "../../assets/images/shap4.png";
import shap5 from "../../assets/images/shap5.png";
import shap6 from "../../assets/images/shap6.png";
import shap7 from "../../assets/images/shap7.png";
import shap8 from "../../assets/images/shap8.jpg";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { useRef } from "react";
import { Fragment } from "react";
import { MdOutlineClose } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../store/Slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStoneClarityData,
  getStoneClarityState,
} from "../../store/Slices/stoneClaritySlice";
import {
  fetchStoneColorData,
  getStoneColorState,
} from "../../store/Slices/stoneColorSlice";
import { AddSnapData } from "../../Services/snapPostService";
import { toast } from "react-hot-toast";
import axios from "axios";
import { getAttributeValueByAttribute } from "../../Services/attributeValueService";
import Loader from "./../Admin/common/Loader";
import Breadcrumb from "./Common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";

const CustomYourDesign = () => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [data, setData] = useState({
    stEmailId: "",
    shape: "",
    metal: "",
    stName: "",
    stPhoneNo: "",
    stRemark1: "",
    stImageURL: "",
  });
  const { data: clarity } = useSelector(getStoneClarityState);
  const { data: color } = useSelector(getStoneColorState);
  const [claritys, setClaritys] = useState(clarity[0]);
  const [colors, setColors] = useState(color[0]);
  const [shapeData, setShapeData] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchStoneClarityData());
    dispatch(fetchStoneColorData());
    fetchShapeData();
    fetchMetalData();
  }, [dispatch]);

  useEffect(() => {
    if (color.length) {
      setColors(color[0]);
    }
  }, [color]);

  useEffect(() => {
    if (clarity.length) {
      setClaritys(clarity[0]);
    }
  }, [clarity]);

  const fetchShapeData = async () => {
    try {
      const response = await getAttributeValueByAttribute(2);
      if (response.data.success) {
        setShapeData(response.data.data.attribute_value);
      }
    } catch (error) {
      console.log("Error: ", error.response);
    }
  };
  const fetchMetalData = async () => {
    try {
      const response = await getAttributeValueByAttribute(1);
      if (response.data.success) {
        setMetalData(response.data.data.attribute_value);
      }
    } catch (error) {
      console.log("Error: ", error.response);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeImage = async (e) => {
    const { name, files } = e.target;
    setSelectedImage(files[0]);
    setData((prev) => ({
      ...prev,
      stImageURL: URL.createObjectURL(files[0]),
    }));
  };

  const uploadImageToCloud = async () => {
    setOpen(false);
    setIsLoading(true);
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("upload_preset", "oocrfsyp");
      await axios
        .post(
          "https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload",
          formData
        )
        .then(async (res) => {
          await handleSubmitContact(res.data.secure_url);
          setIsLoading(false);
        });
    } else {
      await handleSubmitContact("");
      setIsLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setData((prev) => ({ ...prev, stImageURL: "" }));
    setSelectedImage(null);
  };

  const handleChangeShape = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOpenModel = () => {
    setOpen(true);
  };

  const handleSubmitContact = async (img) => {
    const Payload = new FormData();
    Payload.append("stEmailId", data.stEmailId);
    Payload.append("stName", data.stName);
    Payload.append("stPhoneNo", data.stPhoneNo);
    // Payload.append("unCategoryId", selected?.unCategoryIdentifier);
    Payload.append("stImageURL", img);
    Payload.append("stMetal", data.metal?.stAttributeValueName || "");
    Payload.append("stDiaShape", data.shape);
    Payload.append("stDiaColor", colors?.unStoneColorIdentifier);
    Payload.append("stDiaClarity", claritys?.unStoneClarityIdentifier);
    Payload.append("stRemark1", data.stRemark1);
    const res = await AddSnapData(Payload);
    if (res.status === 200) {
      toast.success(res.data.message);
      setOpen(false);
      setData({
        stEmailId: "",
        shape: "",
        metal: "",
        stName: "",
        stPhoneNo: "",
        stRemark1: "",
        stImageURL: "",
      });
      setSelectedImage(null);
    }
  };

  const shapeImages = (shape) => {
    switch (shape.toLowerCase()) {
      case "round":
        return shap3;
      case "oval":
        return shap5;
      case "emerald":
        return shap8;
      case "marquise":
        return shap1;
      case "cushion":
        return shap2;
      case "pear":
        return shap2;
      case "trillion":
        return shap3;
      case "asscher":
        return shap7;
      case "princess":
        return shap4;
      case "radiant":
        return shap6;
      default:
        return shap1;
    }
  };

  const breadCrumbData = [
    {
      title: "Home",
      onClick: () => {
        navigate("/jewelry");
      },
    },
    // {
    //   title: location.state?.prevPath
    //     ? location.state.prevPath
    //         .split("/")[1]
    //         .replace(
    //           location.state.prevPath.split("/")[1][0],
    //           location.state.prevPath.split("/")[1][0].toUpperCase()
    //         )
    //     : "",
    //   onClick: () => {
    //     navigate(location.state.prevPath, {
    //       state: { category: location.state?.category },
    //     });
    //   },
    // },
    {
      title: location.pathname
        ? location.pathname
            .split("/")[1]
            .replace(
              location.pathname.split("/")[1][0],
              location.pathname.split("/")[1][0].toUpperCase()
            )
            .replaceAll("-", " ")
        : "",
    },
  ];

  return (
    <>
      {isLoading && (
        <div className="fixed bg-[#ffffffb0] inset-0 z-50 flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div className="xl:max-w-[1224px] max-w-auto xl:mx-auto mx-[20px] xl:w-full w-auto lg:pb-8 pb-5">
        <div className="my-6">
          <Breadcrumb tabs={breadCrumbData} />
        </div>
        <div className="flex items-start gap-4 lg:flex-row flex-col mb-[21px]">
          <div className="lg:w-[50%] w-full">
            {!data?.stImageURL && (
              <label className="max-w-[100%] mx-auto w-full md:h-[500px] h-[200px] flex flex-col items-center justify-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide border-2 border-blue cursor-pointer">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  Select a Image
                </span>
                <input
                  type="file"
                  onChange={handleChangeImage}
                  name="stImageURL"
                  className="hidden"
                />
                {/* {uploadImage && <BsCheckCircleFill className="text-green-600" />} */}
              </label>
            )}
            {data?.stImageURL && (
              <div className="relative w-full h-full max-w-full max-h-[500px] overflow-hidden">
                <button
                  onClick={handleRemoveImage}
                  className=" absolute top-4 right-4 flex justify-center items-center w-6 h-6 rounded-full bg-white"
                >
                  <AiFillDelete size={16} />
                </button>
                <img
                  src={data?.stImageURL}
                  alt="stImageURL"
                  className=" h-[500px] object-cover block mx-auto  "
                />
              </div>
            )}
          </div>
          <div className="lg:w-[50%] w-full">
            <div>
              <h3 className="text-lg mb-3 text-[16px] leading-[36px] font-[400] text-[#000] font-playfair">
                Center Stone Shape
                {/* {`${data?.shape && ': '}`} */}
                {/* <span className="text-base font-[400]">{data?.shape || ""}</span> */}
              </h3>
              <div className="flex items-center flex-wrap gap-[4px]">
                {shapeData.length > 0 &&
                  shapeData?.map((item, index) => {
                    const image = shapeImages(item.stAttributeValueName);

                    if (!item.stImageURL) return;

                    return (
                      <button
                        key={index}
                        type="button"
                        onClick={handleChangeShape.bind(
                          null,
                          "shape",
                          item.stAttributeValueName
                        )}
                        className={`w-[50px] border-2 rounded border-[#cbd5e1] ${
                          data.shape === item.stAttributeValueName
                            ? "bg-[#f1f5f9]"
                            : ""
                        }`}
                      >
                        <div>
                          <div className="py-2 px-2 ">
                            <img
                              src={item.stImageURL}
                              alt={`shape-${index + 1}`}
                              className="m-auto h-[30px]"
                            />
                          </div>
                          {/* <div className={`text-[11px] ${data.shape === item.stAttributeValueName ? "bg-[#64748b] text-white" : "bg-white text-[#64748b]"}`}>
                            {item.stAttributeValueName}
                          </div> */}
                        </div>
                      </button>
                    );
                  })}
              </div>
              <h3 className="text-lg my-3 font-[400] text-[#000] font-playfair">
                Select Precious Metal
                {/* {`${data.metal?.stAttributeValueName && ': '}`} */}
                {/* <span className="text-base font-[400]">
                  {data?.metal?.stAttributeValueName || ''} 
                </span> */}
              </h3>
              <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
                {metalData?.length > 0 &&
                  metalData?.filter(it => !it?.stAttributeValueAlias?.toLowerCase().includes("14k"))?.map((it,index) => {
                      return (
                        <button
                          type="button"
                          onClick={() => handleChangeShape("metal", it)}
                          className={`border-4 ${
                            it.stAttributeValueAlias === "PL"
                              ? "px-2 py-1 w-[37px] h-[37px]"
                              : "px-1 py-1"
                          } ${
                            data.metal?.stAttributeValueName ===
                              it.stAttributeValueName &&
                            it.stAttributeValueName?.includes("White Gold")
                              ? "bg-[#ececec]"
                              : data.metal?.stAttributeValueName ===
                                  it.stAttributeValueName &&
                                it.stAttributeValueName?.includes("Rose Gold")
                              ? "bg-[#e6be8a]"
                              : data.metal?.stAttributeValueName ===
                                  it.stAttributeValueName &&
                                it.stAttributeValueName?.includes("Yellow Gold")
                              ? "bg-[#d4af37]"
                              : data.metal?.stAttributeValueName ===
                                  it.stAttributeValueName &&
                                it.stAttributeValueName?.includes("Yellow Gold")
                              ? "bg-[#e5e4e2]"
                              : data.metal?.stAttributeValueName ===
                                  it.stAttributeValueName &&
                                it.stAttributeValueName?.includes("Platinum")
                              ? "bg-[#d6d5d5]"
                              : "bg-[#fff]"
                          }
                                    rounded-full ${
                                      it.stAttributeValueName?.includes(
                                        "White Gold"
                                      )
                                        ? "border-[#e4e5e2]"
                                        : it.stAttributeValueName?.includes(
                                            "Rose Gold"
                                          )
                                        ? "border-[#e6be8a]"
                                        : it.stAttributeValueName?.includes(
                                            "Yellow Gold"
                                          )
                                        ? "border-[#d4af37]"
                                        : "border-[#e5e4e2]"
                                    } font-semibold text-[14px]`}
                        >
                          {it.stAttributeValueAlias}
                        </button>
                      );
                    })}
              </div>
              <h3 className="text-lg my-3 font-[400] text-[#000] font-playfair">
                Clarity
                {/* <span className="text-base font-[400]">
                  {claritys?.stStoneClarityName || ""}
                </span> */}
              </h3>
              <RadioGroup value={claritys} onChange={setClaritys}>
                <RadioGroup.Label className="sr-only">
                  Server size
                </RadioGroup.Label>
                <div className="flex items-center justify-start gap-[5px] flex-wrap">
                  {clarity?.length > 0 &&
                    clarity.map((plan) => {
                      if (["SI1", "SI2"]?.includes(plan.stStoneClarityName))
                        return;

                      return (
                        <RadioGroup.Option
                          key={plan.stStoneClarityName}
                          value={plan}
                          className={({ active, checked }) =>
                            `${
                              active
                                ? "ring-2 ring-white ring-opacity-60 ring-offset-2"
                                : ""
                            }
                    ${
                      checked
                        ? "border border-[#cbd5e1] bg-[#f1f5f9] bg-opacity-75 text-white"
                        : "border border-[#cbd5e1] bg-white"
                    }
                      relative sm:max-w-[150px] max-w-[130px] flex cursor-pointer outline-none rounded px-5 py-1.5 mt-0`
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="flex w-full items-center justify-between">
                                <div className="flex items-center">
                                  <div className="text-sm">
                                    <RadioGroup.Label
                                      as="p"
                                      className={`font-medium  ${
                                        checked
                                          ? "text-[#475569]"
                                          : "text-gray-900"
                                      }`}
                                    >
                                      {plan.stStoneClarityName}
                                    </RadioGroup.Label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      );
                    })}
                </div>
              </RadioGroup>
              <h3 className="text-lg my-3 font-[400] text-[#000] font-playfair">
                Color
                {/* <span className="text-base font-[400]">
                  {colors?.stStoneColorName || ""}
                </span> */}
              </h3>
              <RadioGroup value={colors} onChange={setColors}>
                <RadioGroup.Label className="sr-only">
                  Server size
                </RadioGroup.Label>
                <div className="flex items-center justify-start gap-[5px] flex-wrap">
                  {color?.length > 0 &&
                    color.map((plan) => {

                      if (
                        ![
                          "D",
                          "E",
                          "F",
                          "G",
                          "H",
                          "I",
                          "J",
                          "K",
                          "L",
                        ]?.includes(plan?.stStoneColorName)
                      )
                        return;

                      return (
                        <RadioGroup.Option
                          key={plan.stStoneColorName}
                          value={plan}
                          className={({ active, checked }) =>
                            `${
                              active
                                ? "ring-2 ring-white ring-opacity-60 ring-offset-2"
                                : ""
                            }
                    ${
                      checked
                        ? "border border-[#cbd5e1] bg-[#f1f5f9] bg-opacity-75 text-white"
                        : "border border-[#cbd5e1] bg-white"
                    }
                      relative max-w-[250px]  flex cursor-pointer outline-none rounded px-5 py-1.5 focus:outline-none mt-0`
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="flex w-full items-center justify-between">
                                <div className="flex items-center">
                                  <div className="text-sm">
                                    <RadioGroup.Label
                                      as="p"
                                      className={`font-medium  ${
                                        checked
                                          ? "text-[#475569]"
                                          : "text-gray-900"
                                      }`}
                                    >
                                      {plan.stStoneColorName}
                                    </RadioGroup.Label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      );
                    })}
                </div>
              </RadioGroup>

              <div className="mt-6 hidden">
                <button
                  onClick={() => {
                    navigate("/make-own-design", {state: {...data}});
                  }}
                  className="lg:text-[16px] text-[14px] leading-[32px] lg:py-[4px] py-[4px] lg:px-[23px] px-[16px] border border-[#334155] font-[400] text-[#FFF] w-full bg-[#334155] hover:bg-white hover:text-[#334155] font-Josefin duration-200"
                >
                  Customize For Me
                </button>
              </div>
            </div>
          </div>
        </div>
        <textarea
          rows={4}
          name="stRemark1"
          id="stRemark1"
          onChange={handleChange}
          value={data.stRemark1}
          className="block w-full outline-none rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 px-4 py-2 sm:text-sm sm:leading-6 mb-4  "
          placeholder="Remark1"
        />
        <div className="flex justify-center items-center">
          <button
            onClick={handleOpenModel}
            className={`w-[40%] block text-center bg-[#334155] text-[#fff] text-[16px] leading-[32px] py-[6px] font-Josefin`}
          >
            Submit
          </button>
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="">
                      <div className="text-center sm:mt-0 sm:text-left">
                        <div className="bg-[#f1f5f9] sm:py-4 sm:px-6 px-4 pt-5 pb-4 flex items-center justify-between">
                          <Dialog.Title
                            as="h3"
                            className="text-[20px] text-[#334155] font-[600] leading-6"
                          >
                            Snap Your Customize
                          </Dialog.Title>
                          <button
                            type="button"
                            className="text-[20px] text-[#334155] font-[600] leading-6"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            <MdOutlineClose />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 sm:p-6 sm:pb-0 sm:pt-2 px-4 pt-5 pb-4">
                      <div className="mb-2">
                        <label
                          htmlFor="stName"
                          className="block text-sm font-medium leading-4 text-gray-900"
                        >
                          Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="stName"
                            id="stName"
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter your Name"
                          />
                        </div>
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="stEmailId"
                          className="block text-sm font-medium leading-4 text-gray-900"
                        >
                          Email
                        </label>
                        <div className="mt-2">
                          <input
                            type="email"
                            name="stEmailId"
                            id="stEmailId"
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="you@example.com"
                          />
                        </div>
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="stPhoneNo"
                          className="block text-sm font-medium leading-4 text-gray-900"
                        >
                          Phone
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="stPhoneNo"
                            id="stPhoneNo"
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter Your Phone Number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:px-6 px-4 pb-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-[#334155] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#64748b] sm:ml-3 sm:w-auto"
                        onClick={uploadImageToCloud}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default CustomYourDesign;

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
