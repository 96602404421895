import React, { useState } from "react";
import AdminModal from "../common/AdminModal";
import { numberRegexWithDecimals } from "../../shared/constants";
import { diamondStockUpdate } from "../../../Services/diamondService";
import { useEffect } from "react";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import Loading from "../../../assets/images/loading4.gif";
import axios from "axios";

const EditDiamond = ({
  isOpen,
  setIsOpen,
  editData,
  setEditData,
  fetchUpdate,
  setStockData,
}) => {
  const [loader, setLoader] = useState(false)
  const [inputValue, setInputValue] = useState({
    dcRapPrice: "",
    dcBack: "",
    dcPrice: "",
    dcAmount: "",
    stVideoUrl: null,
    stImageUrl: null,
    Location: "",
    IsAvailable: "0"
  });

  const handleClose = useCallback(() => {
    setInputValue({
      dcRapPrice: "",
      dcBack: "",
      dcPrice: "",
      dcAmount: "",
      stVideoUrl: null,
      stImageUrl: null,
      Location: "",
      IsAvailable: ""
    });
    setIsOpen(false);
    setEditData(null);
    setLoader(false)
  }, [setIsOpen, setEditData]);

  useEffect(() => {
    if (editData) {
      setInputValue({
        dcRapPrice: editData.RAP,
        dcBack: editData.Back,
        dcPrice: editData.Price,
        dcAmount: editData.Amount,
        Location: editData.Location,
        stVideoUrl: editData.VideoUrl ? editData.VideoUrl : null,
        stImageUrl: editData.ImageUrl ? editData.ImageUrl : null,
        IsAvailable: editData.IsAvailable
      });
    }
  }, [editData]);

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if (numberRegexWithDecimals.test(value)) {
      if (name === "dcBack") {
        if (parseFloat(value || 0) >= 0 && parseFloat(value || 0) <= 100) {
          setInputValue((prev) => ({ ...prev, [name]: value }));
          setLoader(false)
        }
      } else {
        setInputValue((prev) => ({ ...prev, [name]: value }));
        setLoader(false)
      }
    } else {
      if (!!files[0]) {
        setLoader(true)
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("upload_preset", "oocrfsyp");
        await axios
          .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
          .then((res) => {
            setInputValue((prev) => ({
              ...prev,
              [name]: res.data.secure_url,
            }));
            setLoader(false)
          })
          .catch(error => console.log(error))
          .finally(() => setLoader(false))
      } else {
        setInputValue((prev) => ({ ...prev, [name]: value }));
        setLoader(false)
      }
    }
  }

  const handleSubmit = async () => {
    const payload = new FormData();
    payload.append("unStoneIdentifier", editData?.STOCKNO);
    payload.append("dcRapPrice", inputValue?.dcRapPrice);
    payload.append("dcBack", inputValue?.dcBack);
    payload.append("dcPrice", inputValue?.dcPrice);
    payload.append("dcAmount", inputValue?.dcAmount);
    payload.append("stImageUrl", inputValue?.stImageUrl);
    payload.append("stVideoUrl", inputValue?.stVideoUrl);
    try {
      const res = await diamondStockUpdate(payload);
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        setStockData([]);
        fetchUpdate();
      }
    } catch (err) {
      toast.error("Something went wrong.");
    } finally {
      handleClose();
    }
  };

  return (
    <AdminModal
      title={"Update Diamond"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={handleClose}
      submitButtonText={"Update"}
    >
      <div className="mb-3 grid grid-cols-2 gap-3">
        <div className="flex justify-start items-center gap-2">
          <h3 className="text-sm font-bold ">Stock ID</h3>
          <span>:</span>
          <p className="text-base font-[500] ">{editData?.StoneNo}</p>
        </div>
        <div className="flex justify-start items-center gap-2">
          <h3 className="text-sm font-bold">Cert</h3>
          <span>:</span>
          <p className="text-base font-[500] ">
            {editData?.LAB}-{editData?.CERTID}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <label
            htmlFor="dcRapPrice"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Rbp
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="dcRapPrice"
              value={inputValue.dcRapPrice}
              id="dcRapPrice"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="dcBack"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Back
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="dcBack"
              value={inputValue.dcBack}
              id="dcBack"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="dcPrice"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Price
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="dcPrice"
              value={inputValue.dcPrice}
              id="dcPrice"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="dcAmount"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Amount
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="dcAmount"
              value={inputValue.dcAmount}
              id="dcAmount"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="Location"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Location
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="Location"
              disabled
              value={inputValue.Location}
              id="Location"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="stImageUrl"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Image
          </label>
          <div className="mt-2 flex items-center gap-4">
            <input
              type="file"
              name="stImageUrl"
              // value={inputValue.dcRapPrice}
              id="stImageUrl"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-1">
          <label
            htmlFor="stVideoUrl"
            className="block text-sm font-medium leading-4 text-gray-900"
          >
            Video
          </label>
          <div className="mt-2 flex items-center gap-4">
            <input
              type="file"
              name="stVideoUrl"
              // value={inputValue.dcRapPrice}
              id="stVideoUrl"
              className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              placeholder="Enter Attribute"
              onChange={handleChange}
            />
          </div>
        </div>
        {loader && (
          <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
        )}
      </div>

      <div className="my-2">
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="isAvailable"
            defaultChecked={inputValue.IsAvailable}
            // checked={inputValue.IsAvailable}
            onChange={handleChange}
            id="isAvailable"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="isAvailable"
            className=" text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Is Available
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default EditDiamond;
