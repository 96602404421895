import React, { useEffect, useState } from 'react'
import AdminModal from './common/AdminModal';
import { useMemo } from 'react';
import ModalImage from 'react-modal-image';
import { Tooltip } from '@material-tailwind/react';
import { CgPlayButtonO } from 'react-icons/cg';
import Loader from './common/Loader';
import { FiUpload } from 'react-icons/fi';
import axios from 'axios';
import { diamondStockUpdate } from '../../Services/diamondService';
import { toast } from 'react-hot-toast';
import Loading from "../../assets/images/loading4.gif";
import Compressor from 'compressorjs';

const UploadStockImage = ({ isOpen, setIsOpen, stockData, lastDiamondElementRef, dummyRef, loading, handleVideoModalOpen, fetchUpdate, DiamondStock }) => {

    const [editData, setEditData] = useState()
    const [inputValue, setInputValue] = useState({
        image: "",
        video: ""
    })
    const [editOpen, setEditOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState(true)

    const WithoutImageVideoData = useMemo(() => {
        if (stockData.length > 0) {
            const data = stockData.filter(item => item.ImageUrl === null || item.VideoUrl === null)
            return data
        }
    }, [stockData])

    const handleEdit = (id) => {
        setEditData(id);
        setEditOpen(true);
    };

    const handleClose = () => {
        setEditOpen(false)
        setInputValue({
            image: "",
            video: ""
        });
    }

    const handleChange = async e => {
        const { name, files } = e.target
        setLoader(true)
        new Compressor(files[0], {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            success: async(compressedResult) => {
              // compressedResult has the compressed file.
              // Use the compressed file to upload the images to your server.        
              const formData = new FormData();
              formData.append("file", compressedResult);
              formData.append("upload_preset", "oocrfsyp");
              await axios
                  .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
                  .then((res) => {
                      setInputValue((prev) => ({
                          ...prev,
                          [name]: res.data.secure_url,
                      }));
                      setLoader(false)
                  })
                  .catch(error => console.log(error))
                  .finally(() => setLoader(false))
            },
          });
    }

    useEffect(() => {
        if ((inputValue.image === "" && editData?.ImageUrl !== null) || (inputValue.video === "" && editData?.VideoUrl !== null)) {
            setDisable(false)
        }
        setDisable(true)
    }, [inputValue, editData])
    
    const handleSave = async () => {
        const payload = new FormData();
        payload.append("unStoneIdentifier", editData?.STOCKNO);
        payload.append("stImageUrl", inputValue?.image);
        payload.append("stVideoUrl", inputValue?.video);
        try {
            const res = await diamondStockUpdate(payload);
            if (res?.data?.success) {
                toast.success(res?.data?.message);
                setInputValue({
                    image: "",
                    video: ""
                });
                fetchUpdate();
            }
        } catch (err) {
            toast.error("Something went wrong.");
        } finally {
            handleClose();
            setIsOpen(false)
        }
    }

    return (
        <>
            <AdminModal
                title={"Upload Stock Image/Video"}
                onSubmit={() => { setIsOpen(false) }}
                onClose={() => { setIsOpen(false) }}
                isOpen={isOpen}
                className={" sm:max-w-[75vw] max-h-[90vh]"}
                childClass="overflow-auto"
            >
                <div className="mb-4">
                    {!!WithoutImageVideoData?.length
                        ? WithoutImageVideoData?.map((item, index) => {
                            const certiUrl =
                                item.LAB === "GIA"
                                    ? `https://www.gia.edu/report-check?reportno=${item.CERTID}`
                                    : `https://www.igi.org/viewpdf.php?r=${item.CERTID}`;
                            return (
                                <div
                                    key={index}
                                    ref={
                                        index + 1 === stockData?.length
                                            ? lastDiamondElementRef
                                            : dummyRef
                                    }
                                    className="w-full py-2"
                                >
                                    <div className="bg-white lg:flex hidden items-center lg:gap-4 gap-2 sm:flex-row flex-col shadow rounded-lg hover:shadow-md py-4 sm:px-6 px-4">
                                        <div className="w-full">
                                            <div className="">
                                                <div className="w-full grid grid-cols-9 gap-2">
                                                    <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                                        Shape :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.SHAPE}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold col-span-1 xl:text-sm text-[13px] mb-2">
                                                        Carat :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.CARAT}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                                        Color :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.COLOUR}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                                        Clarity :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.CLARITY}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                                        Cut / Polish / Sym :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.CUTPOLISHSYM}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-2">
                                                        <div className="">
                                                            Cert :{" "}
                                                            <span className="font-normal xl:text-[14px] text-[13px]">{`${item.LAB}-${item.CERTID}`}</span>
                                                        </div>
                                                    </div>
                                                    <div className="font-bold col-span-1 xl:text-sm text-[13px]">
                                                        Location :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px] ">{`${item.Location || "---"
                                                            }`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="w-full grid grid-cols-9 gap-2">
                                                    <div className="font-bold xl:text-sm text-[13px] mt-2 sm:col-span-1 col-span-2">
                                                        Stock ID :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.StoneNo}
                                                        </span>
                                                    </div>
                                                    {/* <div className="font-bold xl:text-sm text-[13px] mb-2 col-span-1">
                                Type :{" "}
                                <span className="font-normal xl:text-[14px] text-[13px]">{item.Type}</span>
                              </div> */}
                                                    <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                                        Measurement :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.MEASUREMENT}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold col-span-1 xl:text-sm text-[13px] mt-2">
                                                        Rbp :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {item.RAP}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold col-span-2 xl:text-sm text-[13px] mt-2">
                                                        Rate(USD) :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px]">
                                                            {Math.round(+item.Price || 0)}
                                                        </span>
                                                    </div>
                                                    <div className="font-bold xl:text-sm text-[13px] mt-2 col-span-2">
                                                        <div>
                                                            Amount(USD) :{" "}
                                                            <span className="font-normal xl:text-[14px] text-[13px]">
                                                                {Math.round(+item.Amount || 0)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="min-w-fit font-bold xl:text-sm text-[13px] mr-3 mt-2 col-span-1">
                                                        {item.IsAvailable === "1" ? (
                                                            <span className="rounded-3xl text-xs bg-green-200 text-green-600 font-normal px-2 py-1 ">
                                                                Available
                                                            </span>
                                                        ) : (
                                                            <span className="rounded-3xl text-xs bg-red-200 text-red-600 font-normal px-2 py-1 ">
                                                                Not available
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="flex lg:gap-4 gap-2 items-center flex-row"> */}
                                        <div className="grid grid-cols-4 lg:gap-4 gap-2 items-center">
                                            {!!item.ImageUrl ? (
                                                <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded">
                                                    <ModalImage
                                                        small={item.ImageUrl}
                                                        large={item.ImageUrl}
                                                        className="sm:w-10 sm:h-10 w-14 h-14 rounded object-cover"
                                                        alt={`Stock ID: ${item.StoneNo}`}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded"></div>
                                            )}
                                            {!!item.VideoUrl ? (
                                                <Tooltip title={"Click to play video"}>
                                                    <div className="sm:w-10 sm:h-10 w-14 h-14 cursor-pointer flex justify-center items-center bg-gray-100 rounded">
                                                        <CgPlayButtonO
                                                            className="text-2xl text-gray-600 "
                                                            onClick={handleVideoModalOpen.bind(
                                                                null,
                                                                item
                                                            )}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <div className="sm:w-10 sm:h-10 w-14 h-14 bg-gray-300 rounded"></div>
                                            )}
                                            <div className="col-span-2">
                                                <div className="flex items-center gap-[10px]">
                                                    <button className='bg-gray-200 rounded sm:h-10 h-14 px-3' onClick={handleEdit.bind(
                                                        null,
                                                        item
                                                    )}><FiUpload /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white lg:hidden flex items-center gap-2 sm:flex-row flex-col shadow rounded-lg overflow-hidden hover:shadow-md py-4 sm:px-6 px-4">
                                        <div className="w-full">
                                            <div className="grid grid-cols-2 gap-1">
                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    Shape :{" "}
                                                    <span className="font-normal">
                                                        {item.SHAPE}
                                                    </span>
                                                </div>
                                                <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                                    Carat :{" "}
                                                    <span className="font-normal">
                                                        {item.CARAT}
                                                    </span>
                                                </div>
                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    Color :{" "}
                                                    <span className="font-normal">
                                                        {item.COLOUR}
                                                    </span>
                                                </div>
                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    Clarity :{" "}
                                                    <span className="font-normal">
                                                        {item.CLARITY}
                                                    </span>
                                                </div>
                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    Cut/Polish/Sym :{" "}
                                                    <span className="font-normal">
                                                        {item.CUTPOLISHSYM}
                                                    </span>
                                                </div>
                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    Cert :{" "}
                                                    <span className="font-normal">
                                                        {item.CERTID}
                                                    </span>
                                                </div>

                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    Stock ID :{" "}
                                                    <span className="font-normal">
                                                        {item.StoneNo}
                                                    </span>
                                                </div>
                                                <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                                    {item.IsAvailable === "1" ? (
                                                        <span className="rounded-3xl text-xs bg-green-200 text-green-600 font-normal px-2 py-1 ">
                                                            Available
                                                        </span>
                                                    ) : (
                                                        <span className="rounded-3xl text-xs bg-red-200 text-red-600 font-normal px-2 py-1 ">
                                                            Not available
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="font-bold col-span-2 sm:text-sm text-[12px] flex justify-between items-center flex-wrap">
                                                    <div>
                                                        Measurement :{" "}
                                                        <span className="font-normal">
                                                            {item.MEASUREMENT}
                                                        </span>
                                                    </div>
                                                    <div className="min-w-fit w-1/2 font-bold xl:text-sm text-[13px]">
                                                        Location :{" "}
                                                        <span className="font-normal xl:text-[14px] text-[13px] ">{`${item.Location || "---"
                                                            }`}</span>
                                                    </div>
                                                </div>
                                                <div className="font-bold col-span-1 sm:text-sm text-[13px] mt-2">
                                                    Rate(USD) :{" "}
                                                    <span className="font-normal">
                                                        {Math.round(+item.Price)}
                                                    </span>
                                                </div>
                                                <div className="font-bold sm:text-sm text-[13px] mt-2 col-span-1">
                                                    Amount(USD) :{" "}
                                                    <span className="font-normal">
                                                        {Math.round(+item.Amount)}
                                                    </span>
                                                </div>

                                                {/* <div className="font-bold sm:text-sm text-[12px] col-span-1">
                                Type :{" "}
                                <span className="font-normal">{item.Type}</span>
                              </div> */}

                                                {/* <div className="font-bold col-span-1 sm:text-sm text-[12px]">
                                Rap :{" "}
                                <span className="font-normal">{item.RAP}</span>
                              </div> */}
                                            </div>
                                        </div>
                                        <div className="flex gap-4 items-center flex-row">
                                            {!!item.ImageUrl ? (
                                                <div className="w-10 h-10 bg-gray-300 rounded">
                                                    <ModalImage
                                                        small={item.ImageUrl}
                                                        large={item.ImageUrl}
                                                        className="w-10 h-10 rounded object-cover"
                                                        alt={`Stock ID: ${item.StoneNo}`}
                                                    />
                                                    {/* <img
                                  src={item.ImageUrl}
                                  alt={`diamond-${index}`}
                                  className="w-10 h-10 rounded"
                                /> */}
                                                </div>
                                            ) : (
                                                <div className="w-10 h-10 bg-gray-300 rounded"></div>
                                            )}
                                            {!!item.VideoUrl ? (
                                                <Tooltip title="Click to play video">
                                                    <div className="w-10 h-10 rounded cursor-pointer flex justify-center items-center bg-gray-100">
                                                        <CgPlayButtonO
                                                            className="text-2xl text-gray-600 "
                                                            onClick={handleVideoModalOpen.bind(
                                                                null,
                                                                item
                                                            )}
                                                        />
                                                        {/* <video
                                  onClick={handleVideoModalOpen.bind(
                                    null,
                                    item
                                  )}
                                  className="w-10 h-10 rounded"
                                  autoPlay
                                  loop
                                  muted
                                >
                                  <source
                                    src={item.VideoUrl}
                                    type="video/mp4"
                                  />
                                </video> */}
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <div className="w-10 h-10 bg-gray-300 rounded"></div>
                                            )}
                                            {/* {!!["gia", "igi"].includes(
                                            item.LAB.toLowerCase()
                                        ) ? (
                                            <div className="w-10 h-10 rounded bg-gray-100 p-1">
                                                <a
                                                    href={certiUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="w-full h-full"
                                                >
                                                    <img
                                                        className="w-full h-full"
                                                        src={
                                                            item.LAB.toLowerCase() === "gia"
                                                                ? Gai
                                                                : Igi
                                                        }
                                                        alt={item.LAB.toLowerCase()}
                                                    />
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="w-10 h-10 flex justify-center items-center bg-gray-300 rounded">
                                                <TbCertificate fontSize={24} />
                                            </div>
                                        )} */}
                                            {/* <div>
                              <button className="bg-[#e2e8f0] rounded p-2.5 shadow-md">
                                <CiViewList className="text-[20px] text-[#475569]" />
                              </button>
                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        : !loading && (
                            <div className="text-[14px] text-gray-500 font-[600] text-center">
                                No Data
                            </div>
                        )}
                    {loading && (
                        <div className="text-center">
                            <Loader />
                        </div>
                    )}
                </div>
            </AdminModal>

            <AdminModal
                title={"Upload Diamond Image/Video"}
                onSubmit={() => handleSave()}
                isOpen={editOpen}
                onClose={() => handleClose()}
                submitButtonText={"Update"}
                disableSubmit={disable}
            >
                <div className="mb-3 grid grid-cols-2 gap-3">
                    <div className="flex justify-start items-center gap-2">
                        <h3 className="text-sm font-bold ">Stock ID</h3>
                        <span>:</span>
                        <p className="text-base font-[500] ">{editData?.StoneNo}</p>
                    </div>
                    <div className="flex justify-start items-center gap-2">
                        <h3 className="text-sm font-bold">Cert</h3>
                        <span>:</span>
                        <p className="text-base font-[500] ">
                            {editData?.LAB}-{editData?.CERTID}
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <label
                            htmlFor="image"
                            className="block text-sm font-medium leading-4 text-gray-900"
                        >
                            Image
                        </label>
                        <div className="mt-2 flex items-center gap-4">
                            <input
                                type="file"
                                name="image"
                                // value={inputValue.dcRapPrice}
                                id="image"
                                className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                placeholder="Enter Attribute"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="video"
                            className="block text-sm font-medium leading-4 text-gray-900"
                        >
                            Video
                        </label>
                        <div className="mt-2 flex items-center gap-4">
                            <input
                                type="file"
                                name="video"
                                // value={inputValue.dcRapPrice}
                                id="video"
                                className="block w-full outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                placeholder="Enter Attribute"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {loader && (
                        <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
                    )}
                    {/* <button className='outline-none rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'>Upload</button> */}
                </div>
            </AdminModal>
        </>
    )
}

export default UploadStockImage