import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import CustomYourDesign from "../Components/LandingComponent/CustomYourDesign";
import { clearSelectedCategory } from "../store/Slices/categorySlice";
import Footer from "../Components/LandingComponent/Footer";
import Header from "../Components/LandingComponent/Header";
import { AddSnapData } from "../Services/snapPostService";
import { getAuthState } from "../store/Slices/AuthSlice";
import axios from "axios";
import TextInputAdmin from "../Components/Admin/common/TextInputAdmin";
import { MdDelete } from "react-icons/md";
import { toast } from "react-hot-toast";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const downloadPDF = (
  images,
  refrenceURL,
  orderNo,
  orderDate,
  cstmCode,
  style,
  bandWidth,
  centerStone,
  lratio,
  wratio,
  dratio,
  ringSize,
  metal,
  stamp,
  engraving,
  deadline
) => {
  const doc = new jsPDF("l", "mm", "a5");

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageBackgroundColor = "#FDFFFB"; // Set your desired page background color here

  // Set page background color
  doc.setFillColor(pageBackgroundColor);
  doc.rect(0, 0, pageWidth, pageHeight, "F");

  // Add border to the entire page
  doc.setDrawColor("gray"); // Border color (black in this case)
  doc.setLineWidth(0.3); // Border width
  doc.rect(
    2,
    2,
    doc.internal.pageSize.width - 4,
    doc.internal.pageSize.height - 4,
    "S"
  ); // 'S' option for stroke

  // Set text properties
  doc.setTextColor("#707070");
  doc.setFontSize(10);

  // Set text to be centered in the row
  const categoryTitle = "Customize For Me";
  const categoryTitleWidth =
    (doc.getStringUnitWidth(categoryTitle) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const textX = (pageWidth - categoryTitleWidth) / 2;

  // Add text to the PDF document
  doc.text(textX, 7, categoryTitle);

  doc.setTextColor("#1c1c1c");
  doc.setFontSize(16);

  // Set text to be centered in the row
  const headerLable = "DTN Premium Quality LAB-GROWN DIAMONDS";
  const headerLableWidth =
    (doc.getStringUnitWidth(headerLable) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const headerLableWidthX = (pageWidth - headerLableWidth) / 2;

  // Add a header to the PDF
  doc.text(headerLableWidthX, 18, headerLable);

  // Add a link to Google in the PDF
  doc.setTextColor("black");
  doc.setFontSize(10);

  if (images?.[0]) doc.addImage(images?.[0], "PNG", 5, 30, 40, 40);

  if (images?.[1]) doc.addImage(images?.[1], "PNG", 47, 30, 40, 40);

  if (images?.[2]) doc.addImage(images?.[2], "PNG", 5, 72, 40, 40);

  if (images?.[3]) doc.addImage(images?.[3], "PNG", 47, 72, 40, 40);

  if (refrenceURL) {
    doc.textWithLink("Click to view Refrence URL", 5, 118.5, {
      url: refrenceURL,
    });
    doc.text("______________________", 5, 119);
  }

  const columns = ["", ""];

  // Define the data for the auto-table

  const bodydata = [
    [{ content: "Order No", styles: { fontStyle: "bold" } }, orderNo || "N/A"],
    [
      { content: "Order Date", styles: { fontStyle: "bold" } },
      orderDate || "N/A",
    ],
    [
      { content: "CSTM Code", styles: { fontStyle: "bold" } },
      cstmCode || "N/A",
    ],

    [{ content: "Style", styles: { fontStyle: "bold" } }, style || "N/A"],
    [
      { content: "Band Width", styles: { fontStyle: "bold" } },
      bandWidth || "N/A",
    ],
    [
      { content: "Center Stone", styles: { fontStyle: "bold" } },
      centerStone || "N/A",
    ],
    [
      { content: "Measurement (L/W/D Ratio)", styles: { fontStyle: "bold" } },
      `${lratio || "N/A"} / ${wratio || "N/A"} / ${dratio || "N/A"}`,
    ],
    [
      { content: "Ring Size", styles: { fontStyle: "bold" } },
      ringSize || "N/A",
    ],
    [{ content: "Metal", styles: { fontStyle: "bold" } }, metal || "N/A"],
    [{ content: "Stamp", styles: { fontStyle: "bold" } }, stamp || "N/A"],
    [
      { content: "Engraving", styles: { fontStyle: "bold" } },
      engraving || "N/A",
    ],
    [{ content: "Deadline", styles: { fontStyle: "bold" } }, deadline || "N/A"],
  ];

  // Define alternative row colors
  const rowColors = {
    odd: [245, 245, 245], // Light gray color for odd rows
    even: [255, 255, 255], // White color for even rows
  };

  // Define a function to apply row colors based on the row index
  const applyRowColors = (dataList) => {
    dataList.forEach((row, index) => {
      if (index % 2 === 0) {
        // Even rows
        row.styles.fillColor = rowColors.even;
      } else {
        // Odd rows
        row.styles.fillColor = rowColors.odd;
      }
    });
  };

  doc.autoTable({
    startY: 30,
    margin: { left: 90 },
    //  head: [columns],
    body: bodydata,
    // didDrawCell: applyRowColors,
    theme: "plain", // Optional: 'striped', 'plain', 'grid' (default)
    styles: { lineWidth: 0.2 }, // Set border width
    columnStyles: {
      0: { columnWidth: 54, fillColor: "#FAFAFA" },
      1: { columnWidth: 60 },
    },
  });

  doc.save(`Customize For Me`);
};

const MakeOwnDesign = () => {
  const [isEdit, setIsEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { state: stateData } = location;

  const { user } = useSelector(getAuthState);

  const selectedCategoryData = useSelector(
    (state) => state.categoryData.selectedCategory
  );

  const [state, setState] = useState({
    images: [],
    orderNo: "",
    orderDate: "",
    cstmCode: "",
    style: "",
    bandWidth: "",
    centerStone: "",
    lratio: "",
    wratio: "",
    dratio: "",
    ringSize: "",
    metal: "",
    stamp: "",
    engraving: "",
    deadline: "",
    refrenceURL: "",
  });

  const {
    images,
    orderNo,
    orderDate,
    cstmCode,
    style,
    bandWidth,
    centerStone,
    lratio,
    wratio,
    dratio,
    ringSize,
    metal,
    stamp,
    engraving,
    deadline,
    refrenceURL,
  } = state;

  const handleChange = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  // const handleChangeImage = async (key, e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setState((prev) => ({
  //         ...prev,
  //         images: { ...images, [key]: reader.result },
  //       }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleChangeImage = async (key, e) => {
    const files = e.target.files; // Get all selected files

    setSelectedImage(Array.from(files));

    const urls = [];

    if (files?.length > 4) {
      toast.error("Please select upto 4 images.");
      return;
    }

    // Loop through each file and process it
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader(); // Create a new FileReader object

      reader.onload = () => {
        const imageDataUrl = reader.result; // Get the data URL from the FileReader result
        urls.push(imageDataUrl); // Add the data URL to the array

        // Check if all files have been processed
        if (urls.length === files.length) {
          setState((prev) => ({
            ...prev,
            images: urls,
          }));
        }
      };

      reader.readAsDataURL(files[i]); // Read each file as a data URL
    }
  };

  const handleDeleteImage = (index) => {
    setState((prev) => ({
      ...prev,
      images: images?.filter((item, i) => i !== index),
    }));
    setSelectedImage(selectedImage?.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (selectedCategoryData) {
      setState((prevState) => ({
        ...prevState,
        style: selectedCategoryData?.[0]?.stCategoryName,
      }));
    }
  }, [selectedCategoryData]);

  useEffect(() => {
    if (stateData?.data) {
      const { data } = stateData;

      setState((prevState) => ({
        ...prevState,
        images: data?.images?.length ? data?.images?.slice(0, 4) : [],
        metal: data?.metal,
        centerStone: data?.centerStoneShape,
        ringSize: data?.ringSize,
        style: data?.category,
      }));
    }
  }, [stateData?.data]);

  // const handleChangeImage = async (e) => {
  //   const { name, files } = e.target;
  //   setSelectedImage(files[0]);
  //   setData((prev) => ({
  //     ...prev,
  //     stImageURL: URL.createObjectURL(files[0]),
  //   }));
  // };

  // const uploadImageToCloud = async () => {
  //   setOpen(false);
  //   setIsLoading(true);
  //   if (selectedImage) {
  //     const formData = new FormData();
  //     formData.append("file", selectedImage);
  //     formData.append("upload_preset", "oocrfsyp");
  //     await axios
  //       .post(
  //         "https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload",
  //         formData
  //       )
  //       .then(async (res) => {
  //         await handleSubmitContact(res.data.secure_url);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     await handleSubmitContact("");
  //     setIsLoading(false);
  //   }
  // };

  // const handleRemoveImage = () => {
  //   setData((prev) => ({ ...prev, stImageURL: "" }));
  //   setSelectedImage(null);
  // };

  const handleSubmit = async () => {
    setLoading(true);

    // Upload images
    const images_array = await Promise.all(
      images?.map(async (image) => {
        if (!image?.includes("res.cloudinary.com")) {
          const formData = new FormData();
          formData.append("file", image);
          formData.append("upload_preset", "oocrfsyp");

          try {
            const res = await axios.post(
              "https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload",
              formData
            );
            return { id: res.data.public_id, url: res.data.secure_url };
          } catch (error) {
            console.error("Error uploading image:", error);
            return null;
          }
        } else {
          return { id: "", url: image };
        }
      })
    );

    const Payload = new FormData();
    Payload.append("stEmailId", user?.stEmail);
    Payload.append("stName", user?.stUserName);
    Payload.append("stPhoneNo", user?.stPhoneNumber);
    Payload.append("stMetal", metal);
    Payload.append("stRefrenceURL", refrenceURL);
    Payload.append("stOrderNo", orderNo);
    Payload.append("dtOrderDate", orderDate);
    Payload.append("stCustomerCode", cstmCode);
    Payload.append("stCategorySize", ringSize);
    Payload.append("stStyle", style);
    Payload.append("stBandWidth", bandWidth);
    Payload.append("stCenterStone", centerStone);
    Payload.append("stLength", lratio);
    Payload.append("stWidth", wratio);
    Payload.append("stDepth", dratio);
    Payload.append("stStamping", stamp);
    Payload.append("stEngraving", engraving);
    Payload.append("dtDeliveryDate", deadline);
    Payload.append("jsonImages", JSON.stringify(images_array));
    const res = await AddSnapData(Payload);
    if (res.status === 200) {
      toast.success(res.data.message);
    }

    setLoading(false);
    setIsEdit(false);
  };

  const breadcrumbs = [
    <div
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => {
        dispatch(clearSelectedCategory());
        navigate(`/home?tab=jewelry`);
      }}
      className="text-xs md:text-sm cursor-pointer"
    >
      Home
    </div>,
    <div
      underline="hover"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearSelectedCategory());
        navigate(
          stateData?.data?.backURL
            ? stateData?.data?.backURL
            : `/category-selection?type=${selectedCategoryData?.[0]?.selectedType}`
        );
      }}
      className="text-xs md:text-sm cursor-pointer"
    >
      Make Own Design
    </div>
  ];

  return (
    <div>
      <Header headerText="Make own design" />
      {/* <CustomYourDesign /> */}

      <div className="flex flex-col md:flex-row xl:max-w-[1224px] max-w-none mx-auto w-full gap-x-12 p-6 py-3 xl:px-0 justify-center">
        <div className="w-full">
          <div className="border-t border-b py-2 border-gray-100">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row xl:max-w-[1224px] max-w-none mx-auto w-full gap-x-12 p-6 pt-0 xl:pb-6 xl:px-0 justify-center">
        <div className="w-full md:w-2/4 lg:w-2/4 xl:w-[50%]">
          {images?.length ? (
            <div className="grid gap-4 grid-cols-2">
              {images?.map((image, index) => {
                return (
                  <label
                    className={`border-gray-200 w-full h-[150px] lg:h-[210px] flex flex-col items-center justify-center bg-white text-blue rounded-lg tracking-wide border-2 border-blue`}
                  >
                    <img
                      src={image}
                      className="w-full h-[150px] lg:h-[210px] rounded-lg p-[2px] object-cover"
                    />

                    <button className="h-7 w-7 absolute z-10 mb-28 ml-[125px] lg:mb-44 lg:ml-[180px] xl:ml-[250px] rounded flex justify-center items-center">
                      <MdDelete
                        className="h-4 w-4 text-gray-500"
                        onClick={() => handleDeleteImage(index)}
                      />
                    </button>
                  </label>
                );
              })}
            </div>
          ) : (
            <div className="w-full">
              <label
                className={`border-gray-200 w-full h-[300px] xl:h-[380px] flex flex-col items-center justify-center ${
                  !images?.image1 && "px-4 py-6"
                } bg-white text-blue rounded-lg tracking-wide border-2 border-blue cursor-pointer`}
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">
                  Select a Images (Max 4)
                </span>
                <input
                  type="file"
                  onChange={(e) => handleChangeImage("image1", e)}
                  name="image1"
                  className="hidden"
                  multiple
                  accept=".jpg,.jpeg,.png"
                />
              </label>
            </div>
          )}

          <div className="py-2 pb-0">
            <label
              htmlFor="stAttributeValueName"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Refrence URL
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="refrenceURL"
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Refrence URL"
                  onChange={(e) => handleChange("refrenceURL", e.target.value)}
                  value={refrenceURL}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {refrenceURL || "N/A"}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/4 lg:w-2/4 xl:w-[50%] grid grid-cols-1 md:grid-cols-2 gap-x-6">
          <div className="mb-2">
            <label
              htmlFor="orderNo"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Order No
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="orderNo"
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Order No"
                  onChange={(e) => handleChange("orderNo", e.target.value)}
                  value={orderNo}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {orderNo || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="orderDate"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Date
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="date"
                  name="orderDate"
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Order Date"
                  onChange={(e) => handleChange("orderDate", e.target.value)}
                  value={orderDate}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {orderDate || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="cstmCode"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              CSTM Code
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="cstmCode"
                  value={cstmCode}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter CSTM Code"
                  onChange={(e) => handleChange("cstmCode", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {cstmCode || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="stAttributeValueName"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Style
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="style"
                  value={style}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Style"
                  onChange={(e) => handleChange("style", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {style || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="bandWidth"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Band Width
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="bandWidth"
                  value={bandWidth}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Band Width"
                  onChange={(e) => handleChange("bandWidth", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {bandWidth || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="stAttributeValueName"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Center Stone
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="centerStone"
                  value={centerStone}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Center Stone"
                  onChange={(e) => handleChange("centerStone", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {centerStone || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="measurement"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Measurement (L/W/D Ratio)
            </label>

            {isEdit ? (
              <div className="flex">
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("lratio", e.target.value)}
                  className="rounded-none shadow-none w-1/3 text-xs text-center"
                  name="lratio"
                  value={lratio}
                  placeholder="Length"
                />
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("wratio", e.target.value)}
                  className="rounded-none shadow-none w-1/3 text-xs text-center"
                  name="wratio"
                  value={wratio}
                  placeholder="Width"
                />
                <TextInputAdmin
                  type="number"
                  onChange={(e) => handleChange("dratio", e.target.value)}
                  className="rounded-none shadow-none w-1/3 text-xs text-center"
                  name="dratio"
                  value={dratio}
                  placeholder="Depth"
                />
              </div>
            ) : (
              <div className="flex">
                <p className="text-xs font-bold text-gray-700">
                  {lratio || "N/A"} / {wratio || "N/A"} / {dratio || "N/A"}
                </p>
              </div>
            )}
          </div>

          <div className="mb-2">
            <label
              htmlFor="stAttributeValueName"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Ring Size
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="ringSize"
                  value={ringSize}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Ring Size"
                  onChange={(e) => handleChange("ringSize", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {ringSize || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="stAttributeValueName"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Metal
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="metal"
                  value={metal}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Metal"
                  onChange={(e) => handleChange("metal", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {metal || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="stamp"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Stamp
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="stamp"
                  value={stamp}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Stamp"
                  onChange={(e) => handleChange("stamp", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {stamp || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="engraving"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Engraving
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="text"
                  name="engraving"
                  value={engraving}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Engraving"
                  onChange={(e) => handleChange("engraving", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {engraving || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="mb-2">
            <label
              htmlFor="deadline"
              className="block text-[14px] font-medium leading-6 text-gray-500"
            >
              Deadline
            </label>
            <div>
              {isEdit ? (
                <TextInputAdmin
                  type="date"
                  name="deadline"
                  value={deadline}
                  className="rounded-none shadow-none text-xs w-2/3"
                  placeholder="Enter Deadline Days"
                  onChange={(e) => handleChange("deadline", e.target.value)}
                />
              ) : (
                <p className="text-xs font-bold text-gray-700">
                  {deadline || "N/A"}
                </p>
              )}
            </div>
          </div>

          {isEdit ? (
            <div className="mt-2 flex flex-col-reverse md:flex-row col-span-1 md:col-span-2 gap-x-6">
              <button
                onClick={() => {
                  setIsEdit(false);
                }}
                className="mt-2 md:mt-0 px-3 py-2 text-sm lg:px-[23px] border border-[#1F2937] font-[400] text-[#1F2937] w-full bg-white hover:bg-white hover:text-[#1F2937] font-Josefin"
              >
                Cancel
              </button>

              <button
                onClick={() => {
                  handleSubmit();
                }}
                className={`px-3 py-2 text-sm lg:px-[23px] border border-[#1F2937] font-[400] text-[#FFF] w-full bg-[#1F2937] hover:bg-white hover:text-[#1F2937] font-Josefin duration-500 ${
                  loading && "opacity-50"
                }`}
                disabled={loading}
              >
                Save
              </button>
            </div>
          ) : (
            <div className="mt-2 flex flex-col-reverse md:flex-row col-span-1 md:col-span-2 gap-x-6">
              <div className="w-full md:w-1/2">
                <button
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  className="mt-2 md:mt-0 px-3 py-2 text-sm lg:px-[23px] border border-[#1F2937] font-[400] text-[#1F2937] w-full bg-white hover:bg-white hover:text-[#1F2937] font-Josefin"
                >
                  Edit
                </button>
              </div>

              <div className="w-full md:w-1/2">
                <button
                  onClick={() => {
                    !loading &&
                      downloadPDF(
                        images,
                        refrenceURL,
                        orderNo,
                        orderDate,
                        cstmCode,
                        style,
                        bandWidth,
                        centerStone,
                        lratio,
                        wratio,
                        dratio,
                        ringSize,
                        metal,
                        stamp,
                        engraving,
                        deadline
                      );
                  }}
                  className="px-3 py-2 text-sm lg:px-[23px] border border-[#1F2937] font-[400] text-[#FFF] w-full bg-[#1F2937] hover:bg-white hover:text-[#1F2937] font-Josefin duration-500"
                >
                  Download
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default MakeOwnDesign;
