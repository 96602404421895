import React from "react";
import AdminModal from "../common/AdminModal";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { createMenuData, updateMenuData } from "../../../Services/menuService";
import { BsCheckCircleFill } from "react-icons/bs";
import Loading from "../../../assets/images/loading4.gif";
import { useParams } from "react-router-dom";

const AddSubMenu = ({
  isOpen,
  setIsOpen,
  editId,
  setEditId,
  fetchData,
  data,
}) => {
  const [inputValue, setInputValue] = useState({
    stMenuName: "",
    stImageURL: "",
    flgIsActive: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (editId) {
      const editData = data.find((item) => item.unMenuIdentifier === editId);
      if (editData) {
        setInputValue({
          stMenuName: editData.stMenuName,
          flgIsActive: editData.flgIsActive === "1" ? true : false,
        });
      }
    }
  }, [editId, data]);

  const handleChangeImage = async (e) => {
    const { name, files } = e.target;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setInputValue((prev) => ({
          ...prev,
          stImageURL: res.data.secure_url,
        }));
        setIsLoading(false);
        setUploadImage(true);
      });
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "isActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setEditId("");
    setInputValue({ stMenuName: "", stImageURL: "", flgIsActive: true });
    setUploadImage(false);
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stMenuName: inputValue.stMenuName,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        unMenuIdentifier: editId,
      };
      const res = await updateMenuData(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData(id);
        handleClose();
      }
    } else {
      const payload = new FormData();
      payload.append("stMenuName", inputValue.stMenuName);
      payload.append("stParentId", id);
      payload.append("stImageURL", inputValue.stImageURL);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await createMenuData(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData(id);
        handleClose();
      }
    }
  };

  return (
    <AdminModal
      title={editId ? "Update Main Menu" : "Add Main Menu"}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        handleClose();
      }}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4">
        <label
          htmlFor="stMenuName"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Menu Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stMenuName"
            value={inputValue.stMenuName}
            id="stMenuName"
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Attribute"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="stImageUrl"
          className="block text-[14px] font-medium leading-6 text-gray-900"
        >
          Image Url
        </label>
        <div className="flex items-center gap-[10px]">
          <div>
            <input
              type="file"
              name="stImageUrl"
              id="stImageUrl"
              className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Enter Image Url"
              onChange={handleChangeImage}
            />
          </div>
          {isLoading && (
            <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
          )}
          {uploadImage && <BsCheckCircleFill className="text-green-600" />}
        </div>
      </div>
      <div className="mb-2">
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="flgIsActive"
            checked={inputValue.flgIsActive}
            onChange={handleChange}
            id="flgIsActive"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="flgIsActive"
            className=" text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddSubMenu;
