import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getReadyToShipById,
  getReadyToShipDesign,
} from "../../Services/readyToShipService";
import {
  getDesignsJStockListV2
} from "../../Services/designService";

export const fetchReadyToShip = createAsyncThunk(
  "design/fetchReadyToShip",
  async (params) => {
    const response = await getReadyToShipDesign(params);
    return response.data.data;
  }
);

export const fetchReadyToShipById = createAsyncThunk(
  "design/fetchReadyToShipById",
  async (params) => {
    const response = await getReadyToShipById(params);
    return response.data.data;
  }
);

export const fetchReadyToShipJStockListV2 = createAsyncThunk(
  "design/fetchReadyToShipJStockListV2",
  async (data) => {
    const response = await getDesignsJStockListV2(data);

    const modifiedData = response.data?.data?.map((item) => ({
      ...item,
      StockID: item?.Id,
      StockName: item?.DesignStockName,
      SalePrice: item?.SaleRate,
      StockNo: item?.DesignStockCode,
      IsSale: 1
    }));

    return { ...response.data, data: modifiedData };
  }
);

const initialState = {
  data: [],
  dataCopy: [],
  loading: false,
  error: null,
  totalCount: 0,
  designLoading: false,
  designError: null,
  design: null,
};

const readyToShipSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    resetError(state) {
      state.designError = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReadyToShip.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReadyToShip.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.jstock;
        state.totalCount = action.payload.total;
        state.error = null;

        if (state.dataCopy.length === 0) {
          state.dataCopy = action.payload.jstock;
        }

      })
      .addCase(fetchReadyToShip.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchReadyToShipById.pending, (state, action) => {
        state.designLoading = true;
        state.designError = null;
      })
      .addCase(fetchReadyToShipById.fulfilled, (state, action) => {
        state.designLoading = false;
        state.design = action.payload;
        state.totalCount = action.payload.total;
        state.designError = null;
      })
      .addCase(fetchReadyToShipById.rejected, (state, action) => {
        state.designLoading = false;
        state.designError = action.error.message;
      })
      .addCase(fetchReadyToShipJStockListV2.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReadyToShipJStockListV2.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.data = action.payload?.data;
          state.error = null;
        } else {
          state.error = action.payload?.message;
        }
      })
      .addCase(fetchReadyToShipJStockListV2.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
  },
});

export const { resetError } = readyToShipSlice.actions;

export const getReadyToShipDataState = (state) => state.readyToShipData;

export default readyToShipSlice.reducer;
