import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Fragment } from "react";
import AutoComplete from "../../common/CustomAutoComplete/AutoComplete";
import TextInputAdmin from "../../common/TextInputAdmin";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  fetchAttributeData,
  getAttributeState,
} from "../../../../store/Slices/attributeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../../../store/Slices/categorySlice";
import { getCategoryStylebyCategoryId } from "../../../../Services/categoryStyleService";
import {
  fetchDesignerData,
  getDesignerDataState,
} from "../../../../store/Slices/designerSlice";
import {
  fetchSupplier,
  getSupplierDataState,
} from "../../../../store/Slices/supplierSlice";
import { postDesign, updateDesign } from "../../../../Services/designService";
import { toast } from "react-hot-toast";
import { getAttributeValueByAttribute } from "../../../../Services/attributeValueService";
import axios from "axios";
import Loading from "../../../../assets/images/loading4.gif";
import { fetchTagsData, getTagsState } from "../../../../store/Slices/tagSlice";
import { useParams } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import {
  fetchDesignById,
  getDesignDataState,
} from "../../../../store/Slices/designSlice";
import { numberRegexWithDecimals } from "../../../shared/constants";
import ImageUpload from "../../../shared/ImageUpload";
import { useRef } from "react";
import { BiSolidVideos } from "react-icons/bi";
import { RiVideoLine } from "react-icons/ri";
import FormError from "../../../shared/FormError";
import { CgPlayButtonO } from "react-icons/cg";

const initialDesignData = {
  stDesignName: "",
  stDesignAlias: "",
  unCategoryId: "",
  unCategoryStyleId: "",
  stRemark1: "",
  unDesignerId: "",
  dcWaxWt: 0,
  dcNtWt: 0,
  dcGrWt: 0,
  dcCostWastePercentage: 0,
  dcCostCADCAM: 0,
  dcCostSetting: 0,
  inShippingDays: "",
  unAccountId: "",
  dcSalePrice: "",
  dcCostLabour: 0,
  dcTotalCost: 0,
  dcCostOther: 0,
  flgIsNew: 0,
  flgIsHotDesign: 0,
  flgIsActive: 0,
  stDefImageURL: "",
  stFocusImageURL: "",
  stMainStones: null,
  inMainStonesPcs: "",
  dcMainStonesCarat: "",
  dcMainStonesAmount: "",
  stSideStones: null,
  inSideStonesPcs: "",
  dcSideStonesCarat: "",
  dcSideStonesAmount: "",
  des: ''
};

export default function StepOne({ basicInfo, setStepCount, setBasicInfo }) {
  const isInitial = useRef(true);
  const [autocomplete, setAutocomplete] = useState([]);
  const [tagAuto, setTagAuto] = useState([]);
  const [metalClarityAuto, setMetalClarityAuto] = useState([]);
  const [centerStoneRangeAuto, setCenterStoneRangeAuto] = useState([]);
  const [mainStoneAuto, setMainStoneAuto] = useState([]);
  const [sideStoneAuto, setSideStoneAuto] = useState([]);
  const [editorValue, setEditorValue] = useState("");
  const [selected, setSelected] = useState();
  const [metalId, setMetalId] = useState();
  const [accountant, setAccountant] = useState();
  const [designerData, setDesignerData] = useState();
  const [shapValue, setShapValue] = useState();
  const dispatch = useDispatch();
  const [categoryStyleData, setCategoryStyleData] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const [shapeData, setShapeData] = useState([]);
  const [centerStoneRangeData, setCenterStoneRangeData] = useState([]);
  const [metalClarityData, setMetalClarityData] = useState([]);
  const [categoryStyle, setCategoryStyle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const designId = localStorage.getItem("design");
  const { id } = useParams();
  const [imageData, setImageData] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [selectVideoError, setSelectVideoError] = useState("");
  const [filesImage, setFileImage] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [designData, setDesignData] = useState(initialDesignData);
  const [error, setError] = useState({
    stDesignName: "",
    stDesignAlias: "",
    unCategoryId: "",
    unCategoryStyleId: "",
    stRemark1: "",
    unDesignerId: "",
    metalId: "",
    shapValue: "",
    inShippingDays: "",
    unAccountId: "",
    dcSalePrice: "",
    editorValue: "",
    stDefImageURL: "",
    stFocusImageURL: "",
    autocomplete: "",
    metalClarityAuto: "",
  });

  const { data: designer } = useSelector(getDesignerDataState);
  const { data: Supplier } = useSelector(getSupplierDataState);
  const { data: tag } = useSelector(getTagsState);
  const { data: category } = useSelector(getCategoryDataState);
  const attributeData = useSelector(getAttributeState);
  const { design: designDatawithId, designLoading } =
    useSelector(getDesignDataState);

  useEffect(() => {
    if (id) {
      dispatch(fetchDesignById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(fetchSupplier());
    dispatch(fetchTagsData());
    dispatch(fetchCategory());
    dispatch(fetchDesignerData());
    fetchMetalData();
  }, [dispatch]);

  useEffect(() => {
    if (
      categoryStyleData?.length &&
      designDatawithId &&
      id &&
      isInitial.current
    ) {
      isInitial.current = false;
      const currentCategoryStyle = categoryStyleData.find(
        (itm) => itm.stCategoryStyleName === designDatawithId.categoryStyle
      );
      if (currentCategoryStyle) {
        setCategoryStyle(currentCategoryStyle);
      }
    }
  }, [categoryStyleData, designDatawithId, id]);

  useEffect(() => {
    if (designDatawithId && id) {
      const currentCategory =
        category?.length &&
        category?.find(
          (itm) => itm.stCategoryName === designDatawithId.category
        );
      if (currentCategory) {
        setSelected(currentCategory);
      }
    }
  }, [designDatawithId, id, category]);

  useEffect(() => {
    if (designDatawithId && id) {
      const currentMetalData =
        metalData?.length &&
        metalData.find(
          (itm) => itm.unAttributeValueIdentifier === designDatawithId.unMetalId
        );
      if (currentMetalData) {
        setMetalId(currentMetalData);
      }
    }
  }, [designDatawithId, id, metalData]);

  useEffect(() => {
    if (designDatawithId && id) {
      const currentShapeData =
        shapeData?.length &&
        shapeData.find(
          (itm) =>
            itm.unAttributeValueIdentifier ===
            designDatawithId.stDefMainStoneShape
        );
      if (currentShapeData) {
        setShapValue(currentShapeData);
      }
    }
  }, [designDatawithId, id, shapeData]);

  useEffect(() => {
    if (designDatawithId && id) {
      const currentDesignerData =
        designer?.length &&
        designer.find(
          (itm) => itm.stDesignerName === designDatawithId.designer
        );
      if (currentDesignerData) {
        setDesignData((prev) => ({
          ...prev,
          unDesignerId: currentDesignerData?.unDesignerIdentifier,
        }));
      }
    }
  }, [designDatawithId, id, designer]);

  const ShapeOption = useMemo(() => {
    return (
      shapeData.length > 0 &&
      shapeData.map((item, i) => ({
        id: item.unAttributeValueIdentifier,
        title: item.stAttributeValueName,
      }))
    );
  }, [shapeData]);

  useEffect(() => {
    if (designDatawithId && id) {
      const currentAccountData =
        Supplier?.length &&
        Supplier.find(
          (itm) => itm.unAccountIdentifier === designDatawithId.unAccountId
        );
      setDesignData((prev) => ({
        ...prev,
        unAccountId: currentAccountData?.unAccountIdentifier,
      }));
    }
  }, [designDatawithId, id, Supplier]);

  const centerStoneRangeOptions = useMemo(() => {
    return centerStoneRangeData.map((item) => ({
      title: item.stAttributeValueName,
      id: item.unAttributeValueIdentifier,
    }));
  }, [centerStoneRangeData]);

  const metalClarityOptions = useMemo(() => {
    return metalClarityData.map((item) => ({
      title: item.stAttributeValueName,
      id: item.unAttributeValueIdentifier,
    }));
  }, [metalClarityData]);

  useEffect(() => {
    if (designDatawithId && id) {
      const AttributeKey = designDatawithId?.designAttribute?.length
        ? designDatawithId?.designAttribute?.map((d) => ({
          title: d?.stAttributeName,
          id: d?.unAttributeId,
        }))
        : [];

      const mainstone =
        designDatawithId.stMainStones &&
        JSON.parse(designDatawithId.stMainStones);
      const mainData =
        mainstone?.length > 0 && ShapeOption?.length > 0
          ? ShapeOption.flatMap((item) => {
            let arr = [];
            mainstone?.map((ite) => {
              if (item.title === ite) {
                arr.push(item);
              }
            });
            return arr;
          })
          : [];
      const sidestone =
        designDatawithId.stSideStones &&
        JSON.parse(designDatawithId.stSideStones);
      const sideData =
        sidestone?.length > 0 && ShapeOption?.length > 0
          ? ShapeOption.flatMap((item) => {
            let arr = [];
            sidestone?.map((ite) => {
              if (item.title === ite) {
                arr.push(item);
              }
            });
            return arr;
          })
          : [];
      setMainStoneAuto(mainData);
      setSideStoneAuto(sideData);

      const tags = designDatawithId?.designTag?.length
        ? designDatawithId?.designTag?.map((d) => ({
          title: d?.stTagName,
          id: d?.unTagIdentifier,
        }))
        : [];

      const Images = designDatawithId?.designImages?.length
        ? designDatawithId?.designImages?.map((d) => {
          return {
            id: d.inSortOrder,
            img: d.stImageURL,
          };
        })
        : [];

      setEditorValue(designDatawithId?.Description1);
      setAutocomplete(AttributeKey);
      setTagAuto(tags);
      setCenterStoneRangeAuto(designDatawithId?.designAttributeValue?.filter(item=>item?.unAttributeId === '99f36160-8f06-4b1b-93f8-4978f3d731d5')?.map(item=>({title: item?.stAttributeValueName, id: item?.unDesignAttributeIdentifier})))
      const sort = Images.sort((a, b) => a.id - b.id);
      setFileImage(sort);
      setAllImages(sort);
      setImageData(sort);

      const exception = ["dcWaxWt", "dcNtWt", "dcGrWt"];
      const chainKeys = Object.keys(initialDesignData);
      chainKeys.forEach((item) => {
        setDesignData((prev) => ({
          ...prev,
          [item]: exception.includes(item)
            ? designDatawithId[item]?.split(".")?.[1]?.length > 2
              ? designDatawithId[item].slice(0, -1)
              : designDatawithId[item]
            : designDatawithId[item],
        }));
      });
    }
  }, [designDatawithId, id, ShapeOption]);

  useEffect(()=>{

    if (metalClarityOptions?.length) {
      const metalOptions = designDatawithId?.designMetalTone?.map((i) => ({
        id: i.unAttributeValueIdentifier,
        title: i.stAttributeValueName,
      }));
      setMetalClarityAuto(metalOptions);
    }

    if(centerStoneRangeOptions?.length)
    {
      const stoneRangeOptions = designDatawithId?.designMainStone?.map((i) => ({
        id: i.unAttributeValueIdentifier,
        title: i.stAttributeValueName,
      }));
      setCenterStoneRangeAuto(stoneRangeOptions)
    }

  },[designDatawithId, metalClarityOptions, centerStoneRangeOptions])

  useEffect(() => {
    const categoryStyleData = async (id) => {
      const res = await getCategoryStylebyCategoryId(id);
      setCategoryStyleData(res.data.data.categories_style);
    };

    if (selected) {
      categoryStyleData(selected?.unCategoryIdentifier);
    }
  }, [selected]);

  const fetchMetalData = async () => {
    const res = await getAttributeValueByAttribute(1);
    const resShape = await getAttributeValueByAttribute(2);
    const resCenterStoneRange = await getAttributeValueByAttribute('99f36160-8f06-4b1b-93f8-4978f3d731d5');
    const resMetalClarity = await getAttributeValueByAttribute('9acb210a-b09d-4453-ab13-c4e3357f330a');
    
    setMetalData(res.data.data.attribute_value);
    setShapeData(resShape.data.data.attribute_value);
    setCenterStoneRangeData(resCenterStoneRange.data.data.attribute_value);
    setMetalClarityData(resMetalClarity.data.data.attribute_value);
  };

  const attributeOptions = useMemo(() => {
    return attributeData.data.map((item) => ({
      title: item.stAttributeName,
      id: item.unAttributeIdentifier,
    }));
  }, [attributeData]);

  const tagOptions = useMemo(() => {
    return tag.map((item) => ({
      title: item.stTagName,
      id: item.unTagIdentifier,
    }));
  }, [tag]);



  useEffect(() => {
    dispatch(fetchAttributeData());
  }, [dispatch]);

  const handleChangeEditor = (e, editor) => {
    const data = editor.getData();
    if (data === "") {
      // setError((prev) => ({
      //   ...prev,
      //   editorValue: "Long Description is required!",
      // }));
    } else {
      setError((prev) => ({ ...prev, editorValue: "" }));
    }
    setEditorValue(data);
  };

  const handleImage = async (e) => {
    const { files } = e.target;
    setLoading(true);
    const image = files[0];
    if (!files) {
      setError((prev) => ({
        ...prev,
        stFocusImageURL: "Image is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stFocusImageURL: "" }));
    }

    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setDesignData((prev) => ({
          ...prev,
          stFocusImageURL: res.data.secure_url,
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handaleImageChanges = async () => {
    if (!allImages.length) return;

    const Images = Array.from(allImages);
    Images?.length &&
      Images?.forEach(async (element, i, arr) => {
        if (typeof element.img === "object") {
          const url =
            element.img.type === "video/mp4"
              ? "https://api.cloudinary.com/v1_1/dgfofaqxf/video/upload"
              : "https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload";

          const formData = new FormData();
          formData.append("file", element.img);
          formData.append("upload_preset", "oocrfsyp");
          if (element.img.type === "video/mp4") {
            formData.append("resource_type", "video");
          }
          setIsLoading(true);
          await axios
            .post(url, formData)
            .then((res) => {
              setImageData((prev) => [
                ...prev,
                { id: element.id, img: res.data.secure_url },
              ]);
              // if (res.status === 200) {
              //   setUploadImage(true);
              //   setIsLoading(false);
              // }
            })
            .catch((err) => {
              toast.error("Something went wrong.");
            });
          // .finally(() => {
          //   setIsLoading(false);
          // });
        }
      });
    // if (Images.length === imageData.length) {
    //   setIsLoading(false);
    // }
  };

  useEffect(() => {
    if (imageData?.length > 0 && allImages?.length > 0) {
      if (imageData?.length === allImages?.length) {
        setUploadImage(true);
        setIsLoading(false);
      }
    } else {
      setUploadImage(false);
    }
  }, [imageData, allImages]);

  const handleDefaultImage = async (e) => {
    setIsLoading(true);
    const { files } = e.target;
    if (!files) {
      setError((prev) => ({
        ...prev,
        stDefImageURL: "Image is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stDefImageURL: "" }));
    }
    const image = files[0];
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setDesignData((prev) => ({
          ...prev,
          stDefImageURL: res.data.secure_url,
        }));
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e, nam) => {
    if (nam) {
      setDesignData((prev) => ({
        ...prev,
        [nam]: e,
      }));
    } else {
      const { name, value, checked } = e.target;
      const checkValue = ["flgIsNew", "flgIsActive", "flgIsHotDesign"];
      const checkedData = checked ? 1 : 0;
      setDesignData((prev) => ({
        ...prev,
        [name]: checkValue.includes(name) ? checkedData : value,
      }));
      if (name === "stDesignName") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            stDesignName: "DesignName is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, stDesignName: "" }));
        }
      }
      if (name === "stDesignAlias") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            stDesignAlias: "stDesignAlias is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, stDesignAlias: "" }));
        }
      }
      if (name === "stRemark1") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            stRemark1: "Short Description is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, stRemark1: "" }));
        }
      }
      if (name === "unDesignerId") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            unDesignerId: "Designer is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, unDesignerId: "" }));
        }
      }
      if (name === "dcSalePrice") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            dcSalePrice: "Sale Price is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, dcSalePrice: "" }));
        }
      }
      if (name === "inShippingDays") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            inShippingDays: "Shipping Days is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, inShippingDays: "" }));
        }
      }
      if (name === "unAccountId") {
        if (value === "") {
          setError((prev) => ({
            ...prev,
            unAccountId: "Menufacture Data is required!",
          }));
        } else {
          setError((prev) => ({ ...prev, unAccountId: "" }));
        }
      }
    }
  };

  const handleFile = (e) => {
    setUploadImage(false);
    setSelectVideoError("");
    let file = e.target.files;
    const arr = [];
    for (let i = 0; i < file.length; i++) {
      if (file[i].type === "video/mp4" && file[i].size > 3072000) {
        setSelectVideoError("video Size must be less than 3.00 MB");
        continue;
      }
      setAllImages((prev) => [...prev, { id: prev.length + 1, img: file[i] }]);
      const urlImage = URL.createObjectURL(file[i]);
      arr.push(urlImage);
      setFileImage((prev) => [...prev, { id: prev.length + 1, img: urlImage }]);
    }
  };

  const removeImage = (i, file) => {
    setFileImage((prev) =>
      prev
        .filter((x, inx) => inx !== i)
        .map((img, index) => ({ ...img, id: index + 1 }))
    );
    setAllImages((prev) =>
      prev
        .filter((x, inx) => inx !== i)
        .map((img, index) => ({ ...img, id: index + 1 }))
    );
    setImageData((prev) =>
      prev
        .filter((img, i) => img.id !== file.id)
        .map((img, index) => ({ ...img, id: index + 1 }))
    );
  };

  const handleSetData = (val, name) => {
    if (name === "category") {
      if (!val?.unCategoryIdentifier) {
        setError((prev) => ({
          ...prev,
          unCategoryId: "Category is required!",
        }));
      } else {
        setSelected(val);
        setError((prev) => ({ ...prev, unCategoryId: "" }));
      }
    }
    if (name === "categoryStyle") {
      if (!val?.unCategoryStyleIdentifier) {
        setError((prev) => ({
          ...prev,
          unCategoryStyleId: "Category Style is required!",
        }));
      } else {
        setCategoryStyle(val);
        setError((prev) => ({ ...prev, unCategoryStyleId: "" }));
      }
    }
    if (name === "metalId") {
      if (!val?.unAttributeValueIdentifier) {
        setError((prev) => ({ ...prev, metalId: "Metal is required!" }));
      } else {
        setMetalId(val);
        setError((prev) => ({ ...prev, metalId: "" }));
      }
    }
    if (name === "shapeValue") {
      if (!val?.unAttributeValueIdentifier) {
        setError((prev) => ({ ...prev, shapValue: "Shape is required!" }));
      } else {
        setShapValue(val);
        setError((prev) => ({ ...prev, shapValue: "" }));
      }
    }
    // if (name === "autoComplate") {
    //   if (val.length === 0) {
    //     setError((prev) => ({
    //       ...prev,
    //       autocomplete: "Attribute is required!",
    //     }));
    //   } else {
    //     setAutocomplete(val);
    //     setError((prev) => ({ ...prev, autocomplete: "" }));
    //   }
    // }
    if (name === "autoComplate") {
      setAutocomplete(val);
      setError((prev) => ({ ...prev, autocomplete: "" }));
    }
  };

  const validation = () => {
    if (designData?.stDesignName === "") {
      setError((prev) => ({
        ...prev,
        stDesignName: "DesignName is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stDesignName: "" }));
    }
    if (designData?.stDesignAlias === "") {
      setError((prev) => ({
        ...prev,
        stDesignAlias: "stDesignAlias is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stDesignAlias: "" }));
    }
    if (!selected?.unCategoryIdentifier) {
      setError((prev) => ({ ...prev, unCategoryId: "Category is required!" }));
    } else {
      setError((prev) => ({ ...prev, unCategoryId: "" }));
    }
    if (!categoryStyle?.unCategoryStyleIdentifier) {
      setError((prev) => ({
        ...prev,
        unCategoryStyleId: "Category Style is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, unCategoryStyleId: "" }));
    }
    if (designData?.stRemark1 === "") {
      setError((prev) => ({
        ...prev,
        stRemark1: "Short Description is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stRemark1: "" }));
    }
    if (designData?.unDesignerId === "") {
      setError((prev) => ({ ...prev, unDesignerId: "Designer is required!" }));
    } else {
      setError((prev) => ({ ...prev, unDesignerId: "" }));
    }
    if (!metalId?.unAttributeValueIdentifier) {
      setError((prev) => ({ ...prev, metalId: "Metal is required!" }));
    } else {
      setError((prev) => ({ ...prev, metalId: "" }));
    }
    if (!shapValue?.unAttributeValueIdentifier) {
      setError((prev) => ({ ...prev, shapValue: "Shape is required!" }));
    } else {
      setError((prev) => ({ ...prev, shapValue: "" }));
    }
    if (designData.dcSalePrice === "" || designData.dcSalePrice === 0) {
      setError((prev) => ({ ...prev, dcSalePrice: "Sale Price is required!" }));
    } else {
      setError((prev) => ({ ...prev, dcSalePrice: "" }));
    }
    if (designData.inShippingDays === "") {
      setError((prev) => ({
        ...prev,
        inShippingDays: "Shipping Days is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, inShippingDays: "" }));
    }
    if (designData.unAccountId === "") {
      setError((prev) => ({
        ...prev,
        unAccountId: "Menufacture Data is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, unAccountId: "" }));
    }
    if (designData.stDefImageURL === "") {
      setError((prev) => ({
        ...prev,
        stDefImageURL: "Image is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stDefImageURL: "" }));
    }
    if (designData.stFocusImageURL === "") {
      setError((prev) => ({
        ...prev,
        stFocusImageURL: "Image is required!",
      }));
    } else {
      setError((prev) => ({ ...prev, stFocusImageURL: "" }));
    }

    if (editorValue === "") {
      // setError((prev) => ({
      //   ...prev,
      //   editorValue: "Long Description is required!",
      // }));
    } else {
      setError((prev) => ({ ...prev, editorValue: "" }));
    }

    // if (autocomplete.length === 0) {
    //   setError((prev) => ({ ...prev, autocomplete: "Attribute is required!" }));
    // } else {
    //   setError((prev) => ({ ...prev, autocomplete: "" }));
    // }

    if (!metalClarityAuto?.length) {
      setError((prev) => ({ ...prev, metalClarityAuto: "Metal clarity is required!" }));
    } else {
      setError((prev) => ({ ...prev, metalClarityAuto: "" }));
    }

  };

  const handleSave = async () => {
    if (id) {
      const payload = {
        unDesignIdentifier: id,
        stDesignName: designData?.stDesignName,
        stDesignAlias: designData?.stDesignAlias,
        unCategoryId: selected?.unCategoryIdentifier,
        unCategoryStyleId: categoryStyle?.unCategoryStyleIdentifier,
        stRemark1: designData?.stRemark1,
        unDesignerId: designData?.unDesignerId,
        unMetalId: metalId?.unAttributeValueIdentifier,
        stDefMainStoneShape: shapValue?.unAttributeValueIdentifier,
        dcWaxWt: designData?.dcWaxWt || 0,
        dcNtWt: designData?.dcNtWt || 0,
        dcGrWt: designData?.dcGrWt || 0,
        dcSalePrice: designData?.dcSalePrice,
        dcCostWastePercentage: designData?.dcCostWastePercentage || 0,
        dcCostCADCAM: designData?.dcCostCADCAM,
        dcCostLabour: designData?.dcCostLabour,
        dcCostSetting: designData?.dcCostSetting,
        dcCostMetal: 0,
        dcCostStone: 0,
        dcTotalCost: designData.dcTotalCost || 0,
        inShippingDays: designData?.inShippingDays,
        unAccountId: designData?.unAccountId,
        dcCostOther: designData?.dcCostOther,
        flgIsNew: designData?.flgIsNew,
        flgIsActive: designData?.flgIsActive,
        flgIsHotDesign: designData?.flgIsHotDesign,
        DesignImages: JSON.stringify(imageData),
        unCreatedById: "98c2f0b9-e33a-4c47-8f94-1982a747e13c",
        Description1: editorValue,
        stDefImageURL: designData?.stDefImageURL,
        stFocusImageURL: designData?.stFocusImageURL,
        stAttributeKey: JSON.stringify(autocomplete.map((item) => item.id)),
        stTag: JSON.stringify(tagAuto.map((item) => item.id)),
        stMetalTone: JSON.stringify(metalClarityAuto.map((item) => item.id)),
        stMainStone: JSON.stringify(centerStoneRangeAuto.map((item) => item.id)),
        stMainStones: JSON.stringify(mainStoneAuto.map((item) => item.title)),
        inMainStonesPcs: designData?.inMainStonesPcs,
        dcMainStonesCarat: designData?.dcMainStonesCarat,
        dcMainStonesAmount: designData?.dcMainStonesAmount,
        stSideStones: JSON.stringify(sideStoneAuto.map((item) => item.title)),
        inSideStonesPcs: designData?.inSideStonesPcs,
        dcSideStonesCarat: designData?.dcSideStonesCarat,
        dcSideStonesAmount: designData?.dcSideStonesAmount,
      };

      const res = await updateDesign(payload);
      if (res.status === 200) {
        localStorage.setItem("design", res.data.data.unDesignIdentifier);
        localStorage.setItem(
          "attributeKey",
          JSON.stringify(autocomplete.map((item) => item.id))
        );
        setUploadImage(false);
        setStepCount((prev) => (prev === 2 ? prev : prev + 1));
      }
    } else {
      if (
        designData?.stDesignName &&
        designData?.stDesignAlias &&
        designData?.stFocusImageURL &&
        designData?.stDefImageURL &&
        selected?.unCategoryIdentifier &&
        categoryStyle?.unCategoryStyleIdentifier &&
        designData?.stRemark1 &&
        metalId?.unAttributeValueIdentifier &&
        shapValue?.unAttributeValueIdentifier &&
        designData?.unDesignerId &&
        designData?.unAccountId &&
        designData?.inShippingDays &&
        designData?.dcSalePrice &&
        // imageData.length > 0 &&
        // editorValue &&
        autocomplete
      ) {
        const DesignData = new FormData();
        DesignData.append("stDesignName", designData?.stDesignName);
        DesignData.append("stDesignAlias", designData?.stDesignAlias);
        DesignData.append("unCategoryId", selected?.unCategoryIdentifier);
        DesignData.append(
          "unCategoryStyleId",
          categoryStyle?.unCategoryStyleIdentifier
        );
        // DesignData.append('inSrNumber')
        DesignData.append("stRemark1", designData?.stRemark1);
        DesignData.append("unDesignerId", designData?.unDesignerId);
        DesignData.append("unMetalId", metalId?.unAttributeValueIdentifier);
        DesignData.append(
          "stDefMainStoneShape",
          shapValue?.unAttributeValueIdentifier
        );
        DesignData.append("dcWaxWt", designData?.dcWaxWt);
        DesignData.append("dcNtWt", designData?.dcNtWt);
        DesignData.append("DesignImages", JSON.stringify(imageData));
        DesignData.append("dcGrWt", designData?.dcGrWt);
        DesignData.append("dcSalePrice", designData?.dcSalePrice);
        DesignData.append(
          "dcCostWastePercentage",
          designData?.dcCostWastePercentage
        );
        DesignData.append("dcCostCADCAM", 0);
        DesignData.append("dcCostLabour", 0);
        DesignData.append("dcCostSetting", 0);
        DesignData.append("dcCostMetal", 0);
        DesignData.append("dcCostStone", 0);
        DesignData.append("dcTotalCost", designData?.dcTotalCost);
        DesignData.append("inShippingDays", designData?.inShippingDays);
        DesignData.append("unAccountId", designData?.unAccountId);
        DesignData.append("dcCostOther", 0);
        DesignData.append("flgIsNew", designData?.flgIsNew);
        DesignData.append("flgIsActive", designData?.flgIsActive);
        DesignData.append("flgIsHotDesign", designData?.flgIsHotDesign);
        DesignData.append(
          "unCreatedById",
          "98c2f0b9-e33a-4c47-8f94-1982a747e13c"
        );
        DesignData.append("Description1", editorValue);
        DesignData.append("stDefImageURL", designData?.stDefImageURL);
        DesignData.append("stFocusImageURL", designData?.stFocusImageURL);
        DesignData.append(
          "stAttributeKey",
          JSON.stringify(autocomplete.map((item) => item.id))
        );
        DesignData.append(
          "stTag",
          JSON.stringify(tagAuto.map((item) => item.id))
        );
        DesignData.append(
          "stMetalTone",
          JSON.stringify(metalClarityAuto.map((item) => item.id))
        );
        DesignData.append(
          "stMainStone",
          JSON.stringify(centerStoneRangeAuto.map((item) => item.id))
        );
        DesignData.append(
          "stMainStones",
          JSON.stringify(mainStoneAuto.map((item) => item.title))
        );
        DesignData.append("inMainStonesPcs", designData?.inMainStonesPcs);
        DesignData.append("dcMainStonesCarat", designData?.dcMainStonesCarat);
        DesignData.append("dcMainStonesAmount", designData?.dcMainStonesAmount);
        DesignData.append(
          "stSideStones",
          JSON.stringify(sideStoneAuto.map((item) => item.title))
        );
        DesignData.append("inSideStonesPcs", designData?.inSideStonesPcs);
        DesignData.append("dcSideStonesCarat", designData?.dcSideStonesCarat);
        DesignData.append("dcSideStonesAmount", designData?.dcSideStonesAmount);
        try {
          const res = await postDesign(DesignData);
          if (res.status === 200) {
            localStorage.setItem("design", res.data.data.unDesignIdentifier);
            localStorage.setItem(
              "attributeKey",
              JSON.stringify(autocomplete.map((item) => item.id))
            );
            setUploadImage(false);
            setStepCount((prev) => (prev === 2 ? prev : prev + 1));
          }
        } catch (err) {
          toast.error("Something went wrong.");
        }
      } else if (designId) {
        setStepCount((prev) => (prev === 2 ? prev : prev + 1));
      } else {
        validation();
      }
    }
  };

  useEffect(() => {
    if (basicInfo) {
      handleSave();
      setBasicInfo(false);
    }
  }, [basicInfo]);

  if (designLoading) {
    return (
      <div className="w-full h-[400px] flex justify-center items-center">
        <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Basic Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
            <div className="col-span-3">
              <label
                htmlFor="stDesignAlias"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Design Number
                <span className="text-red-500">*</span>
              </label>
              <TextInputAdmin
                type="text"
                value={designData.stDesignAlias}
                onChange={handleChange}
                name="stDesignAlias"
              />
              {error.stDesignAlias !== "" && (
                <p className="text-red-600 text-[12px]">
                  {error.stDesignAlias}
                </p>
              )}
            </div>
            <div className="sm:col-span-full col-span-3">
              <label
                htmlFor="stDesignName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
                <span className="text-red-500">*</span>
              </label>
              <TextInputAdmin
                type="text"
                value={designData.stDesignName}
                onChange={handleChange}
                name="stDesignName"
              />
              {error.stDesignName !== "" && (
                <p className="text-red-600 text-[12px]">{error.stDesignName}</p>
              )}
            </div>
            <div className="col-span-3">
              <div className="">
                <div>
                  <label
                    htmlFor="stDefImageURL"
                    className="block text-sm font-medium leading-0 text-gray-900"
                  >
                    Default Image
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center gap-x-3">
                    <PhotoIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <label
                      htmlFor="stDefImageURL"
                      className="rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Change
                    </label>
                    {isLoading && (
                      <img
                        src={Loading}
                        alt="loading"
                        className="w-[40px] h-[40px]"
                      />
                    )}
                  </div>
                  <ImageUpload
                    id="stDefImageURL"
                    onChange={(e) => handleDefaultImage(e)}
                    className="rounded-md hidden bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  />
                </div>
                {error.stDefImageURL !== "" && (
                  <p className="text-red-600 text-[12px]">
                    {error.stDefImageURL}
                  </p>
                )}
              </div>
              <div className="">
                <div className="border border-dashed border-[#94a3b8] w-[200px] p-2 rounded">
                  {designData.stDefImageURL ? (
                    <img
                      src={designData.stDefImageURL}
                      alt="defaultImage"
                      className="w-[200px] h-[200px] mx-auto"
                    />
                  ) : (
                    <div className="text-[#94a3b8] text-[14px] font-Poppins h-[200px] flex items-center justify-center">
                      Preview
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="">
                <label
                  htmlFor="stFocusImageURL"
                  className="block text-sm font-medium leading-0 text-gray-900"
                >
                  Hover Image
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex items-center gap-x-3">
                  <PhotoIcon
                    className="h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <label
                    htmlFor="stFocusImageURL"
                    className="rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </label>
                  {loading && (
                    <img
                      src={Loading}
                      alt="loading"
                      className="w-[40px] h-[40px]"
                    />
                  )}
                </div>
                <ImageUpload
                  id="stFocusImageURL"
                  onChange={(e) => handleImage(e)}
                  className="rounded-md hidden bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                />
              </div>
              {error.stFocusImageURL !== "" && (
                <p className="text-red-600 text-[12px]">
                  {error.stFocusImageURL}
                </p>
              )}
              <div className="">
                <div className="border border-dashed border-[#94a3b8] w-[200px] p-2 rounded">
                  {designData.stFocusImageURL ? (
                    <img
                      src={designData.stFocusImageURL}
                      alt="hover"
                      className="w-[200px] h-[200px]"
                    />
                  ) : (
                    <div className="text-[#94a3b8] text-[14px] font-Poppins h-[200px] flex items-center justify-center">
                      Preview
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Category
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={selected}
                onChange={(val) => {
                  handleSetData(val, "category");
                  setCategoryStyleData([]);
                  setCategoryStyle(null);
                }}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {selected ? selected?.stCategoryName : "Select Category"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {category?.length &&
                        category?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stCategoryName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {error.unCategoryId !== "" && (
                <p className="text-red-600 text-[12px]">{error.unCategoryId}</p>
              )}
            </div>
            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Style
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={categoryStyle}
                onChange={(val) => handleSetData(val, "categoryStyle")}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate">
                      {categoryStyle
                        ? categoryStyle?.stCategoryStyleName
                        : "Select Category Style"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {categoryStyleData?.length ? (
                        categoryStyleData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stCategoryStyleName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))
                      ) : (
                        <p className="text-[12px] text-gray-400 text-center">
                          First Select Category
                        </p>
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {error.unCategoryStyleId !== "" && (
                <p className="text-red-600 text-[12px]">
                  {error.unCategoryStyleId}
                </p>
              )}
            </div>

            <div className="sm:col-span-full col-span-3">
              <label
                htmlFor="stRemark1"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Design Short Description
                <span className="text-red-500">*</span>
              </label>
              <div>
                <textarea
                  id="stRemark1"
                  name="stRemark1"
                  rows={3}
                  onChange={handleChange}
                  value={designData?.stRemark1}
                  className="block w-full outline-0 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:p-1.5 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
              {error.stRemark1 !== "" && (
                <p className="text-red-600 text-[12px]">{error.stRemark1}</p>
              )}
            </div>

            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Metal Id
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={metalId}
                onChange={(val) => handleSetData(val, "metalId")}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {metalId ? metalId?.stAttributeValueName : "Select Metal"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {metalData?.length &&
                        metalData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stAttributeValueName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {error.metalId !== "" && (
                <p className="text-red-600 text-[12px]">{error.metalId}</p>
              )}
            </div>
            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Main Stone Shape
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={shapValue}
                onChange={(val) => handleSetData(val, "shapeValue")}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate">
                      {shapValue
                        ? shapValue?.stAttributeValueName
                        : "Select Center Stone"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {shapeData?.length &&
                        shapeData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stAttributeValueName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {error.shapValue !== "" && (
                <p className="text-red-600 text-[12px]">{error.shapValue}</p>
              )}
            </div>

            <div className="col-span-3">
              <label
                htmlFor="unDesignerId"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Designer
                <span className="text-red-500">*</span>
              </label>
              <div className="relative h-[38px] shadow-sm rounded border-[1px] border-[#D1D5DB]">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <select
                    id="unDesignerId"
                    name="unDesignerId"
                    onChange={handleChange}
                    value={designData.unDesignerId}
                    className="w-[230px] h-[36px] outline-0	 rounded-md border-0 bg-transparent bg-none py-0 pl-2  sm:text-sm"
                  >
                    <option defaultChecked>Choose Designer</option>
                    {designer?.length &&
                      designer.map((item, i) => {
                        return (
                          <option key={i} value={item?.unDesignerIdentifier}>
                            {item?.stDesignerName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <input
                  type="text"
                  name="dcCostWastePercentage"
                  id="dcCostWastePercentage"
                  onChange={handleChange}
                  value={designData.dcCostWastePercentage}
                  placeholder="Comm.(%)"
                  className="block w-full h-[36px] outline-0 rounded-md border-0 py-2 px-3.5 pl-60 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
              </div>
              {error.unDesignerId !== "" && (
                <p className="text-red-600 text-[12px]">{error.unDesignerId}</p>
              )}
            </div>
            <div className="col-span-3">
              <label
                htmlFor="menufecture"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Manufacturer
                <span className="text-red-500">*</span>
              </label>
              <div className="relative h-[38px] shadow-sm rounded border-[1px] border-[#D1D5DB]">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <select
                    id="unAccountId"
                    name="unAccountId"
                    onChange={handleChange}
                    value={designData.unAccountId}
                    className="h-[36px] w-[230px] outline-0 rounded-md border-0 bg-transparent bg-none py-0 pl-2 sm:text-sm"
                  >
                    <option defaultChecked>Choose Supplier</option>
                    {Supplier?.length &&
                      Supplier?.map((item, i) => {
                        return (
                          <option key={i} value={item.unAccountIdentifier}>
                            {item.stAccountName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <input
                  type="text"
                  name="inShippingDays"
                  id="inShippingDays"
                  onChange={handleChange}
                  value={designData.inShippingDays}
                  placeholder="Ship Day"
                  className="block h-[36px] w-full outline-0 rounded-md border-0 py-2 px-3.5 pl-60 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {error.inShippingDays !== "" && (
                <p className="text-red-600 text-[12px]">
                  {error.inShippingDays}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Multiple Design Images
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Side Multiple Images, Video
            </p>
          </div>

          <div className="col-span-2">
            <div className="mb-3">
              <span className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                Images
                <span className="text-red-500">*</span>
              </span>
              <div className="flex items-center gap-[20px]">
                <div className="shadow rounded-lg">
                  <label
                    htmlFor="formFile"
                    className="border rounded-l-lg px-3 py-[9px] text-[14px] bg-[#fff]"
                  >
                    Choose image
                  </label>
                  <input
                    type="text"
                    disabled
                    className="border rounded-r-lg py-1.5 px-2 bg-[#fff]"
                    value={
                      !allImages?.length
                        ? "No Images"
                        : allImages?.length + " " + "Files"
                    }
                  />
                </div>

                <button
                  className="border rounded px-3 py-2 text-[13px] bg-white"
                  onClick={handaleImageChanges}
                >
                  Upload
                </button>

                {isLoading && (
                  <img
                    src={Loading}
                    alt="loading"
                    className="w-[40px] h-[40px]"
                  />
                )}
                {uploadImage && (
                  <BsCheckCircleFill className="text-green-600" />
                )}
              </div>
              <ImageUpload
                className=" hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                id="formFile"
                onChange={(e) => {
                  handleFile(e);
                }}
                multiple
                video
              />
              {selectVideoError ? (
                <FormError>{selectVideoError}</FormError>
              ) : null}
              <div className="flex flex-wrap gap-2 mt-2">
                {filesImage.map((file, key) => {
                  const findVideo = allImages.filter(
                    (item) => item.img.type === "video/mp4"
                  );
                  const findId = findVideo.find((item) => item.id === file.id);
                  if (findId || file.img.endsWith("mp4")) {
                    return (
                      <div key={key} className="overflow-hidden relative">
                        <GrFormClose
                          onClick={() => {
                            removeImage(key, file);
                          }}
                          className="absolute right-1 text-white cursor-pointer"
                        />
                        {file.img.endsWith("mp4") ? (
                          <div className="h-20 w-20 rounded-md bg-white flex items-center justify-center">
                            <CgPlayButtonO className="text-[40px] w-[30px] h-[30px]" />
                          </div>
                        ) : (
                          <div className="h-20 w-20 rounded-md bg-white flex items-center justify-center">
                            <CgPlayButtonO className="text-[40px] w-[30px] h-[30px]" />
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <div key={key} className="overflow-hidden relative">
                        <GrFormClose
                          onClick={() => {
                            removeImage(key, file);
                          }}
                          className="absolute right-1 text-white cursor-pointer"
                        />
                        <img
                          className="h-20 w-20 rounded-md"
                          src={file.img}
                          alt={`data-${key}`}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Long Description
              {/* <span className="text-red-500">*</span> */}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Long Details Description
            </p>
          </div>

          <div className="max-w-2xl md:col-span-2">
            <CKEditor
              editor={ClassicEditor}
              data={editorValue || "<p></p>"}
              // value={designData?.des}
              onChange={handleChangeEditor}
            />
            {error.editorValue !== "" && (
              <p className="text-red-600 text-[12px]">{error.editorValue}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Main Stone Details (Optional)
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Metal Gross, Net and Wax Weight Information
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-y-4 gap-x-1 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Shape
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => setMainStoneAuto(data)}
                defValue={mainStoneAuto}
                options={ShapeOption}
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Pcs.
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <TextInputAdmin
                type="text"
                value={designData.inMainStonesPcs || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="inMainStonesPcs"
                className="rounded py-[5px]"
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Carat
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <TextInputAdmin
                type="text"
                value={designData.dcMainStonesCarat || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcMainStonesCarat"
                className="rounded py-[5px]"
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Amount
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <TextInputAdmin
                type="text"
                value={designData.dcMainStonesAmount || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcMainStonesAmount"
                className="rounded py-[5px]"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Side Stone Details (Optional)
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Metal Gross, Net and Wax Weight Information
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-y-4 gap-x-1 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Shape
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => setSideStoneAuto(data)}
                defValue={sideStoneAuto}
                options={ShapeOption}
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Pcs.
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <TextInputAdmin
                type="text"
                value={designData.inSideStonesPcs || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="inSideStonesPcs"
                className="rounded py-[5px]"
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Carat
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <TextInputAdmin
                type="text"
                value={designData.dcSideStonesCarat || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcSideStonesCarat"
                className="rounded py-[5px]"
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Amount
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <TextInputAdmin
                type="text"
                value={designData.dcSideStonesAmount || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcSideStonesAmount"
                className="rounded py-[5px]"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Design Weight
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Metal Gross, Net and Wax Weight Information
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-y-4 gap-x-6 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="dcWaxWt"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Wax Weight (Optinal)
              </label>
              <TextInputAdmin
                type="text"
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcWaxWt"
                value={designData?.dcWaxWt}
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="dcNtWt"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Net Weight (Metal)
              </label>
              <TextInputAdmin
                type="text"
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcNtWt"
                value={designData?.dcNtWt}
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="dcGrWt"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Gross Weight (Jewelry)
              </label>
              <TextInputAdmin
                type="text"
                value={designData?.dcGrWt}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcGrWt"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-4 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Design Cost & Setting Sale Price
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Sell price = All Other Expenses With Margin
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-y-4 gap-x-6 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label
                htmlFor="dcTotalCost"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Total Cost
              </label>
              <TextInputAdmin
                type="text"
                value={designData.dcTotalCost}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcTotalCost"
              />
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="dcSalePrice"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Sell Price (Except Metal)
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="dcSalePrice"
                value={designData.dcSalePrice}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                className="block w-full outline-0	 bg-[#f1f5f9] rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#64748b] placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
              {error.dcSalePrice !== "" && (
                <p className="text-red-600 text-[12px]">{error.dcSalePrice}</p>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Attribute
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Add Design Attribute for Create Multiple Variation
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-y-3 gap-x-6 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-full col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Attribute
                <span className="text-red-500">*</span>
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => handleSetData(data, "autoComplate")}
                defValue={autocomplete}
                options={attributeOptions}
              />
              {error.autocomplete !== "" && (
                <p className="text-red-600 text-[12px]">{error.autocomplete}</p>
              )}
            </div>
            <div className="sm:col-span-full col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Tag
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => setTagAuto(data)}
                defValue={tagAuto}
                options={tagOptions}
              />
            </div>
            <div className="sm:col-span-full col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Select Metal Clarity
                <span className="text-red-500">*</span>
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => setMetalClarityAuto(data)}
                defValue={metalClarityAuto}
                options={metalClarityOptions}
              />
               {error.metalClarityAuto !== "" && (
                <p className="text-red-600 text-[12px]">{error.metalClarityAuto}</p>
              )}
            </div>
            <div className="sm:col-span-full col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Center Stone Range
              </label>
              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => setCenterStoneRangeAuto(data)}
                defValue={centerStoneRangeAuto}
                options={centerStoneRangeOptions}
              />
            </div>
            <div className="my-1 col-span-full">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  name="flgIsNew"
                  checked={!!Number(designData.flgIsNew)}
                  onChange={handleChange}
                  id="flgIsNew"
                  className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
                />
                <label
                  htmlFor="flgIsNew"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is New
                </label>
              </div>
            </div>
            <div className="my-1 col-span-full">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  name="flgIsHotDesign"
                  checked={!!Number(designData.flgIsHotDesign)}
                  onChange={handleChange}
                  id="flgIsNew"
                  className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
                />
                <label
                  htmlFor="flgIsHot"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is Hot Design (Hot Selling)
                </label>
              </div>
            </div>
            <div className="col-span-full">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  name="flgIsActive"
                  checked={!!Number(designData.flgIsActive)}
                  onChange={handleChange}
                  id="flgIsActive"
                  className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
                />
                <label
                  htmlFor="flgIsActive"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is Active
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
