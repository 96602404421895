import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createTags, updateTags } from "../../../Services/tagService";
import { fetchTagsData, getTagsState } from "../../../store/Slices/tagSlice";
import AdminModal from "../common/AdminModal";
import { BsCheckCircleFill } from "react-icons/bs";
import Loading from "../../../assets/images/loading4.gif";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
import ImageUpload from "../../shared/ImageUpload";

const AddTag = ({ isOpen, setIsOpen, editId, setEditId }) => {
  const [inputValue, setInputValue] = useState({
    stTagName: "",
    stImageURL: "",
    flgIsActive: true,
  });
  const [filesImage, setFileImage] = useState();
  const [allImages, setAllImages] = useState([]);
  const [imageData, setImageData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const { data } = useSelector(getTagsState);
  const dispatch = useDispatch();

  useEffect(() => {
    const editData = data.find((item) => item.unTagIdentifier === editId);
    if (editData) {
      setInputValue({
        stTagName: editData.stTagName,
        stImageURL: editData.stImageURL,
        flgIsActive: editData.flgIsActive === "1" ? true : false,
      });
    }
  }, [editId, data]);

  const handaleImageChanges = async () => {
    if (!allImages.length) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", allImages[0]);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setImageData(res.data.secure_url);
        setIsLoading(false);
        setUploadImage(true);
      });
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: name === "flgIsActive" ? checked : value,
    }));
  };

  const handleClose = () => {
    setIsOpen(false);
    setInputValue({
      stTagName: "",
      stImageURL: "",
      flgIsActive: true,
    });
    setImageData();
    setFileImage();
    setIsLoading(false);
    setUploadImage(false);
    setEditId("");
    setAllImages();
  };

  const handleFile = (e) => {
    let file = e.target.files[0];
    const urlImage = URL.createObjectURL(file);
    setFileImage(urlImage);
  };

  const removeImage = () => {
    setFileImage();
    setAllImages();
  };

  const handleSubmit = async () => {
    if (editId) {
      const payload = {
        stTagName: inputValue.stTagName,
        stImageURL: imageData,
        flgIsActive: inputValue.flgIsActive ? "1" : "0",
        unTagIdentifier: editId,
      };
      const res = await updateTags(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchTagsData());
        setIsOpen(false);
        setEditId();
      }
    } else {
      const payload = new FormData();
      payload.append("stTagName", inputValue.stTagName);
      payload.append("stImageURL", imageData);
      payload.append("flgIsActive", inputValue.flgIsActive ? "1" : "0");
      const res = await createTags(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(fetchTagsData());
      }
    }
    handleClose();
  };
  return (
    <AdminModal
      title={editId ? "Update Tag" : "Add Tag"}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isOpen={isOpen}
      submitButtonText={editId ? "Update" : "Save"}
    >
      <div className="mb-4">
        <label
          htmlFor="stTagName"
          className="block text-sm font-medium leading-4 text-gray-900"
        >
          Tag Name
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="stTagName"
            id="stTagName"
            value={inputValue.stTagName}
            onChange={handleChange}
            className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Tag Name"
          />
        </div>
      </div>
      <div className="sm:col-span-6">
        <span className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
          Images
        </span>
        <div className="flex items-center gap-[20px] sm:flex-row flex-col">
          <div>
            <label
              htmlFor="formFile"
              className="border rounded-l-lg px-3 py-[9px] text-[13px]"
            >
              Choose image
            </label>
            <input
              type="text"
              disabled
              className="border rounded-r-lg py-2 text-[13px] px-2"
              value={!!allImages?.length ? 1 + " " + "Files" : "Choose Images"}
            />
          </div>

          <button
            className="border rounded px-3 py-2 text-[13px]"
            onClick={handaleImageChanges}
          >
            Upload
          </button>
          {isLoading && (
            <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
          )}
          {uploadImage && <BsCheckCircleFill className="text-green-600" />}
        </div>
        <ImageUpload
          className=" hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
          id="formFile"
          onChange={(e) => {
            handleFile(e);
            setAllImages(e.target.files);
          }}
        />
        <div className="flex flex-wrap gap-2 mt-2">
          {!!filesImage ? (
            <div className="overflow-hidden relative">
              <GrFormClose
                onClick={() => {
                  removeImage(filesImage);
                }}
                className="absolute right-1 text-white cursor-pointer"
              />
              <img className="h-20 w-20 rounded-md" alt="filesImage" src={filesImage} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center gap-[10px]">
          <input
            type="checkbox"
            name="flgIsActive"
            checked={inputValue.flgIsActive}
            onChange={handleChange}
            id="flgIsActive"
            className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
          />
          <label
            htmlFor="flgIsActive"
            className=" block text-sm font-medium leading-6 text-gray-900"
          >
            is Active
          </label>
        </div>
      </div>
    </AdminModal>
  );
};

export default AddTag;
