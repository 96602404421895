import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Fragment } from "react";
import AutoComplete from "../../common/CustomAutoComplete/AutoComplete";
import TextInputAdmin from "../../common/TextInputAdmin";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  fetchAttributeData,
  getAttributeState,
} from "../../../../store/Slices/attributeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";
import {
  fetchCategory,
  getCategoryDataState,
} from "../../../../store/Slices/categorySlice";
import { getCategoryStylebyCategoryId } from "../../../../Services/categoryStyleService";
import {
  fetchDesignerData,
  getDesignerDataState,
} from "../../../../store/Slices/designerSlice";
import {
  fetchSupplier,
  getSupplierDataState,
} from "../../../../store/Slices/supplierSlice";
import { postDesign, updateDesign } from "../../../../Services/designService";
import { toast } from "react-hot-toast";
import { getAttributeValueByAttribute } from "../../../../Services/attributeValueService";
import axios from "axios";
import Loading from "../../../../assets/images/loading4.gif";
import { fetchTagsData, getTagsState } from "../../../../store/Slices/tagSlice";
import { useNavigate, useParams } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import {
  fetchDesignById,
  fetchDesigns,
  getDesignDataState,
} from "../../../../store/Slices/designSlice";
import { numberRegexWithDecimals } from "../../../shared/constants";
import ImageUpload from "../../../shared/ImageUpload";
import { useRef } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import SelectMenu from "../../common/SelectMenu";
import SelectMenuTableView from "../../common/SelectMenuTableView";
import {
  fetchStoneSizeData,
  getStoneSizeState,
} from "../../../../store/Slices/stoneSizeSlice";
import {
  fetchStoneColorData,
  getStoneColorState,
} from "../../../../store/Slices/stoneColorSlice";
import {
  fetchStoneClarityData,
  getStoneClarityState,
} from "../../../../store/Slices/stoneClaritySlice";
import {
  postReadyToShipDesign,
  updateReadyToShipDesign,
} from "../../../../Services/readyToShipService";
import {
  fetchReadyToShipById,
  getReadyToShipDataState,
} from "../../../../store/Slices/readyToShipSlice";
import { useCallback } from "react";
import { RiVideoLine } from "react-icons/ri";
import { CgPlayButtonO } from "react-icons/cg";

const initialDesignData = {
  stDesign: "",
  StockNo: "",
  stDesignName: "",
  stDefImageURL: "",
  stFocusImageURL: "",
  stRemark1: "",
  dcNtWt: "",
  dcGrWt: "",
  dcTotalCost: "",
  dcSalePrice: "",
  attribute: {},
  flgIsActive: 0,
  flgIsNew: 0,
  flgIsHotDesign: 0,
};

export default function StepOne({
  basicInfo,
  isSubmit,
  setIsSubmit,
  setStepCount,
  setBasicInfo,
  isCancel,
  setIsCancel,
}) {
  const userId = localStorage.name ? JSON.parse(localStorage.name) : {};
  const mainStoneDef = {
    StockJvType: "",
    Shape: null,
    Sieve: null,
    Colour: null,
    Clarity: null,
    Carat: null,
    Rate: null,
    Pcs: null,
    Amount: null,
    LAB: null,
    CertiId: null,
    StoneNo: null,
    Depth: null,
    Length: null,
    Width: null,
    ShortNarr1: null,
  };
  const isInitial = useRef(true);
  const [mainStoneAuto, setMainStoneAuto] = useState([]);
  const [errorValidation, setErrorValidation] = useState({
    stDesign: "",
    StockNo: "",
    stDesignName: "",
    stDefImageURL: "",
    stFocusImageURL: "",
    stRemark1: "",
    dcSalePrice: "",
    metalId: "",
    shapeValue: "",
    editorValue: "",
    imageData: "",
    unCategoryId: "",
    unCategoryStyleId: "",
  });
  const [mainStoneDetails, setMainStoneDetails] = useState([]);
  const [sideStoneAuto, setSideStoneAuto] = useState([]);
  const [sideStoneDetails, setSideStoneDetails] = useState([]);
  const [editorValue, setEditorValue] = useState("");
  const [selected, setSelected] = useState();
  const [metalId, setMetalId] = useState();
  const [accountant, setAccountant] = useState();
  const [shapValue, setShapValue] = useState();
  const dispatch = useDispatch();
  const [categoryStyleData, setCategoryStyleData] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const [shapeData, setShapeData] = useState([]);
  const [categoryStyle, setCategoryStyle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const designId = localStorage.getItem("design");
  const { id } = useParams();
  const [imageData, setImageData] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [filesImage, setFileImage] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [tagAuto, setTagAuto] = useState([]);
  const [attributeValueData, setAttributeValueData] = useState([]);
  const [designData, setDesignData] = useState(initialDesignData);
  const { data: tag } = useSelector(getTagsState);
  const { data: category } = useSelector(getCategoryDataState);
  const attributeData = useSelector(getAttributeState);
  const { data: size, error } = useSelector(getStoneSizeState);
  const { data: color } = useSelector(getStoneColorState);
  const { data: clarity } = useSelector(getStoneClarityState);
  const { data: designDatawithId, designLoading } =
    useSelector(getDesignDataState);
  const { design, designLoading: readytoshipLoading } = useSelector(
    getReadyToShipDataState
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      dispatch(fetchReadyToShipById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchTagsData());
    fetchMetalData();
    dispatch(fetchStoneColorData());
    dispatch(fetchStoneSizeData());
    dispatch(fetchStoneClarityData());
    dispatch(
      fetchDesigns({
        inPageNo: 1,
        inPageSize: 100000,
        unUserId: userId?.unUserIdentifier,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (categoryStyleData?.length && design && id && isInitial.current) {
      isInitial.current = false;
      const currentCategoryStyle = categoryStyleData.find(
        (itm) => itm.stCategoryStyleName === design.CategoryStyleName
      );
      if (currentCategoryStyle) {
        setCategoryStyle(currentCategoryStyle);
      }
    }
  }, [categoryStyleData, design, id]);

  useEffect(() => {
    if (design && id) {
      const currentCategory =
        category?.length &&
        category?.find((itm) => itm.stCategoryName === design.CategoryName);
      if (currentCategory) {
        setSelected(currentCategory);
      }
    }
  }, [design, id, category]);

  const sizeOption = useMemo(() => {
    const arr = [];
    size.length > 0 &&
      size.map((item, i) => {
        const data = {
          id: item.unStoneSizeIdentifier,
          shapeId: item.unAttributeValueShapeId,
          title: item.stStoneSizeName,
        };
        arr.push(data);
      });
    return arr;
  }, [size]);

  const colorOption = useMemo(() => {
    const arr = [];
    color.length > 0 &&
      color.map((item, i) => {
        const data = {
          id: item.unStoneColorIdentifier,
          title: item.stStoneColorName,
        };
        arr.push(data);
      });
    return arr;
  }, [color]);

  const clarityOption = useMemo(() => {
    const arr = [];
    clarity.length > 0 &&
      clarity.map((item, i) => {
        const data = {
          id: item.unStoneClarityIdentifier,
          title: item.stStoneClarityName,
        };
        arr.push(data);
      });
    return arr;
  }, [clarity]);

  const settingTypeOptions = [
    {
      id: "",
      title: "None",
    },
    {
      id: "Side",
      title: "Side",
    },
    {
      id: "Accent",
      title: "Accent",
    },
  ];


  {
    /* 
  const designOption = useMemo(() => {
    const first = {
      id: "00000000-0000-0000-0000-000000000000",
      title: `None`,
      name: "None",
    };
    if (designDatawithId.length) {
      const data = designDatawithId.map((item, i) => {
        return {
          id: item.unDesignIdentifier,
          title: `${item.stDesignAlias} - ${item.stDesignName}`,
          name: item.stDesignName,
        };
      });
      return [first, ...data];
    }
    return [first];
  }, [designDatawithId]);
 

  useEffect(() => {
    if (id) {
      setDesignData((prev) => ({
        ...prev,
        stDesignName: design?.StockName || design?.DesignName,
      }));
    } else {
      setDesignData((prev) => ({
        ...prev,
        stDesignName: designData.stDesign.name,
      }));
    }
  }, [designData.stDesign, id, design]);
 */
  }

  const sizeSelect = (shape) => {
    const filterData =
      sizeOption.length > 0 &&
      sizeOption.filter((item) => item.shapeId === shape);
    return filterData || [];
  };

  useEffect(() => {
    if (designDatawithId && id) {
      const currentMetalData =
        metalData?.length &&
        metalData.find(
          (itm) => itm.unAttributeValueIdentifier === designDatawithId.unMetalId
        );
      if (currentMetalData) {
        setMetalId(currentMetalData);
      }
    }
  }, [designDatawithId, id, metalData]);

  useEffect(() => {
    if (designDatawithId && id) {
      const currentShapeData =
        shapeData?.length &&
        shapeData.find(
          (itm) =>
            itm.unAttributeValueIdentifier ===
            designDatawithId.stDefMainStoneShape
        );
      if (currentShapeData) {
        setShapValue(currentShapeData);
      }
    }
  }, [designDatawithId, id, shapeData]);

  const ShapeOption = useMemo(() => {
    return (
      shapeData.length > 0 &&
      shapeData.map((item, i) => ({
        id: item.unAttributeValueIdentifier,
        title: item.stAttributeValueName,
      }))
    );
  }, [shapeData]);

  useEffect(() => {
    const tag = designData?.tags
      ? designData?.tags?.map((item) => ({
          title: item?.stTagName,
          id: item?.unTagIdentifier,
        }))
      : [];
    setTagAuto(tag);
  }, [designData?.tags]);

  const getAttributeValue = useCallback(
    (title, attName) => {
      const findData =
        attributeValueData[attName]?.length > 0 &&
        attributeValueData[attName].find((item) => item.title === title);
      return findData;
    },
    [attributeValueData]
  );

  // useEffect(() => {
  //   if (
  //     categoryStyleData?.length &&
  //     designDatawithId &&
  //     id &&
  //     isInitial.current
  //   ) {
  //     isInitial.current = false;
  //     const currentCategoryStyle = categoryStyleData.find(
  //       (itm) => itm.stCategoryStyleName === designDatawithId.categoryStyle
  //     );
  //     if (currentCategoryStyle) {
  //       setCategoryStyle(currentCategoryStyle);
  //     }
  //   }
  // }, [categoryStyleData, designDatawithId, id]);

  // useEffect(() => {
  //   if (designDatawithId && id) {
  //     const currentCategory =
  //       category?.length &&
  //       category?.find(
  //         (itm) => itm.stCategoryName === designDatawithId.category
  //       );
  //     if (currentCategory) {
  //       setSelected(currentCategory);
  //     }
  //   }
  // }, [designDatawithId, id, category]);

  useEffect(() => {
    if (design && id) {
      const mainStoneDetail =
        design?.details?.length > 0 &&
        design.details.filter((item) => item.StockJvType === "main");
      const sideStoneDetail =
        design?.details?.length > 0 &&
        design.details.filter((item) => item.StockJvType === "side");

      setMainStoneDetails(mainStoneDetail);
      setSideStoneDetails(sideStoneDetail);
      const designData = {
        id: design.DesignId,
        title: design.DesignAlias || "None",
        name: design.DesignName || "None",
      };

      design.attribute.length > 0 &&
        design.attribute.map((item) => {
          setDesignData((prev) => ({
            ...prev,
            attribute: {
              ...prev.attribute,
              [item.Attribute.unAttributeIdentifier]: getAttributeValue(
                item.AttributeValue,
                item.Attribute.stAttributeStock
              ),
            },
          }));
        });

      setDesignData((prev) => ({
        ...prev,
        stDesign: design.DesignId,
        stDesignName: design.StockName,
        StockNo: design.StockNo,
        stDefImageURL: design.DefImageURL,
        stFocusImageURL: design.FocusImageURL,
        stRemark1: design.DesignRemark,
        dcNtWt: design.NetWt,
        dcGrWt: design.GrossWt,
        dcTotalCost: design.TotalCost,
        dcSalePrice: design.SalePrice,
        flgIsActive:
          design.IsSale === 1 || design.IsSale === "1" ? true : false,
        flgIsHotDesign:
          design.IsHotStock === 1 || design.IsHotStock === "1" ? true : false,
        flgIsNew: design.IsNew === 1 || design.IsNew === "1" ? true : false,
        tags: design.tags,
      }));

      const mainstone = design.AttributeValueMetalId;
      const mainData =
        metalData?.length > 0
          ? metalData.find((item) => item.stAttributeValueName === mainstone)
          : [];
      const sidestone = design.AttributeValueShapeId;
      const sideData =
        shapeData?.length > 0
          ? shapeData.find((item) => item.stAttributeValueName === sidestone)
          : [];
      setMetalId(mainData);
      setShapValue(sideData);

      const Images = design?.images?.length
        ? design?.images?.map((d) => {
            return {
              id: d.SrNumber,
              img: d.ImageURL,
            };
          })
        : [];

      setEditorValue(design?.DesignLongRemark);
      const sort = Images.sort((a, b) => a.id - b.id);
      setImageData(sort.map((item) => ({ ...item, ImageURL: item.img })));
      setFileImage(sort);
      setAllImages(sort);
    }
  }, [design, id, ShapeOption, metalData, shapeData, getAttributeValue]);

  useEffect(() => {
    const categoryStyleData = async (id) => {
      const res = await getCategoryStylebyCategoryId(id);
      setCategoryStyleData(res.data.data.categories_style);
    };

    if (selected) {
      categoryStyleData(selected?.unCategoryIdentifier);
    }
  }, [selected]);

  const fetchMetalData = async () => {
    const res = await getAttributeValueByAttribute(1);
    const resShape = await getAttributeValueByAttribute(2);
    setMetalData(res.data.data.attribute_value);
    setShapeData(resShape.data.data.attribute_value);
  };

  const attributeOptions = useMemo(() => {
    return attributeData.data.map((item) => ({
      title: item.stAttributeStock,
      id: item.unAttributeIdentifier,
    }));
  }, [attributeData]);

  useEffect(() => {
    dispatch(fetchAttributeData());
  }, [dispatch]);

  const handleChangeEditor = (e, editor) => {
    const data = editor.getData();
    if (data === "") {
      // setErrorValidation((prev) => ({
      //   ...prev,
      //   editorValue: "Long Description is Requied!",
      // }));
    } else {
      setErrorValidation((prev) => ({ ...prev, editorValue: "" }));
    }
    setEditorValue(data);
  };

  const handleImage = async (e) => {
    const { files } = e.target;
    setLoading(true);
    if (!files) {
      setErrorValidation((prev) => ({
        ...prev,
        stFocusImageURL: "Image is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, stFocusImageURL: "" }));
    }
    const image = files[0];

    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setDesignData((prev) => ({
          ...prev,
          stFocusImageURL: res.data.secure_url,
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handaleImageChanges = async () => {
    if (!allImages.length) return;

    setIsLoading(true);
    const Images = Array.from(allImages);
    Images?.length &&
      Images?.forEach(async (element, i) => {
        const url =
          element.img.type === "video/mp4"
            ? "https://api.cloudinary.com/v1_1/dgfofaqxf/video/upload"
            : "https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload";
        if (typeof element.img === "object") {
          const formData = new FormData();
          formData.append("file", element.img);
          formData.append("upload_preset", "oocrfsyp");
          if (element.img.type === "video/mp4") {
            formData.append("resource_type", "video");
          }
          await axios
            .post(url, formData)
            .then((res) => {
              setImageData((prev) => [
                ...prev,
                {
                  SrNumber: element.id,
                  ImageURL: res.data.secure_url,
                  VideoURL: "",
                },
              ]);
            })
            .catch((err) => {
              toast.error("Something went wrong.");
              setIsLoading(false);
            });
        }
      });
  };

  useEffect(() => {
    if (imageData?.length > 0 && allImages?.length > 0) {
      if (imageData?.length === allImages?.length) {
        setUploadImage(true);
        setIsLoading(false);
      }
    } else {
      setUploadImage(false);
    }
  }, [imageData, allImages]);

  const handleDefaultImage = async (e) => {
    setIsLoading(true);
    const { files } = e.target;
    const image = files[0];
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "oocrfsyp");
    await axios
      .post("https://api.cloudinary.com/v1_1/dgfofaqxf/image/upload", formData)
      .then((res) => {
        setDesignData((prev) => ({
          ...prev,
          stDefImageURL: res.data.secure_url,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong.");
      });
  };

  const handleChange = (e, nam) => {


    const { name, value, checked } = e.target;
    const checkValue = ["flgIsActive", "flgIsHotDesign", "flgIsNew"];
    const checkedData = checked ? 1 : 0;
    setDesignData((prev) => ({
      ...prev,
      [name]: checkValue.includes(name) ? checkedData : value,
    }));

    if (name === "stDesignName") {
      if (value === "") {
        setErrorValidation((prev) => ({
          ...prev,
          stDesignName: "Design Name is Requied!",
        }));
      } else {
        setErrorValidation((prev) => ({ ...prev, stDesignName: "" }));
      }
    }

    if (name === "StockNo") {
      if (value === "") {
        setErrorValidation((prev) => ({
          ...prev,
          StockNo: "Stock No. is Requied!",
        }));
      } else {
        setErrorValidation((prev) => ({ ...prev, StockNo: "" }));
      }
    }

    if (name === "stDefImageURL") {
      if (value === "") {
        setErrorValidation((prev) => ({
          ...prev,
          stDefImageURL: "Image is Requied!",
        }));
      } else {
        setErrorValidation((prev) => ({ ...prev, stDefImageURL: "" }));
      }
    }
    if (name === "stFocusImageURL") {
      if (value === "") {
        setErrorValidation((prev) => ({
          ...prev,
          stFocusImageURL: "Image is Requied!",
        }));
      } else {
        setErrorValidation((prev) => ({ ...prev, stFocusImageURL: "" }));
      }
    }
    if (name === "stRemark1") {
      if (value === "") {
        setErrorValidation((prev) => ({
          ...prev,
          stRemark1: "Short Description is Requied!",
        }));
      } else {
        setErrorValidation((prev) => ({ ...prev, stRemark1: "" }));
      }
    }
    if (name === "dcSalePrice") {
      if (value === "" || value === 0) {
        setErrorValidation((prev) => ({
          ...prev,
          dcSalePrice: "Sale Price is Requied!",
        }));
      } else {
        setErrorValidation((prev) => ({ ...prev, dcSalePrice: "" }));
      }
    }
  };

  const handleSetData = (val, name) => {
    if (name === "category") {
      if (!val?.unCategoryIdentifier) {
        setErrorValidation((prev) => ({
          ...prev,
          unCategoryId: "Category is required!",
        }));
      } else {
        if (selected?.unCategoryIdentifier !== val?.unCategoryIdentifier) {
          setCategoryStyleData([]);
          setCategoryStyle(null);
        }
        setSelected(val);
        setErrorValidation((prev) => ({ ...prev, unCategoryId: "" }));
      }
    }
    if (name === "categoryStyle") {
      if (!val?.unCategoryStyleIdentifier) {
        setErrorValidation((prev) => ({
          ...prev,
          unCategoryStyleId: "Category Style is required!",
        }));
      } else {
        setCategoryStyle(val);
        setErrorValidation((prev) => ({ ...prev, unCategoryStyleId: "" }));
      }
    }
    if (name === "metalId") {
      if (!val?.unAttributeValueIdentifier) {
        setErrorValidation((prev) => ({
          ...prev,
          metalId: "Metal is required!",
        }));
      } else {
        setMetalId(val);
        setErrorValidation((prev) => ({ ...prev, metalId: "" }));
      }
    }
    if (name === "shapeValue") {
      if (!val?.unAttributeValueIdentifier) {
        setErrorValidation((prev) => ({
          ...prev,
          shapeValue: "Shape is required!",
        }));
      } else {
        setShapValue(val);
        setErrorValidation((prev) => ({ ...prev, shapeValue: "" }));
      }
    }
  };

  const handleFile = (e) => {
    let file = e.target.files;
    setUploadImage(false);
    const arr = [];
    for (let i = 0; i < file.length; i++) {
      setAllImages((prev) => [
        ...prev,
        { id: prev.length + 1, img: file[i], VideoURL: "" },
      ]);
      const urlImage = URL.createObjectURL(file[i]);
      arr.push(urlImage);
      setFileImage((prev) => [
        ...prev,
        { id: prev.length + 1, img: urlImage, VideoURL: "" },
      ]);
    }
  };

  const handleChangeAttribute = (att, attVal) => {
    setDesignData((prev) => ({
      ...prev,
      attribute: { ...prev.attribute, [att]: attVal },
    }));
  };

  const removeImage = (i) => {
    setFileImage((prev) =>
      prev
        .filter((x, inx) => inx !== i)
        .map((img, index) => ({ ...img, id: index + 1 }))
    );
    setAllImages((prev) =>
      prev
        .filter((x, inx) => inx !== i)
        .map((img, index) => ({ ...img, id: index + 1 }))
    );
    setImageData((prev) =>
      prev
        .filter((x, inx) => inx !== i)
        .map((img, index) => ({ ...img, id: index + 1 }))
    );
  };

  const validation = () => {
    if (designData.stDesignName === "") {
      setErrorValidation((prev) => ({
        ...prev,
        stDesignName: "Design Name is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, stDesignName: "" }));
    }
    if (designData.StockNo === "") {
      setErrorValidation((prev) => ({
        ...prev,
        StockNo: "Stock No. is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, StockNo: "" }));
    }
    if (designData.stDefImageURL === "") {
      setErrorValidation((prev) => ({
        ...prev,
        stDefImageURL: "Image is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, stDefImageURL: "" }));
    }
    if (designData.stFocusImageURL === "") {
      setErrorValidation((prev) => ({
        ...prev,
        stFocusImageURL: "Image is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, stFocusImageURL: "" }));
    }
    if (designData.stRemark1 === "") {
      setErrorValidation((prev) => ({
        ...prev,
        stRemark1: "Short Description is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, stRemark1: "" }));
    }
    if (!metalId?.unAttributeValueIdentifier) {
      setErrorValidation((prev) => ({
        ...prev,
        metalId: "Metal Data is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, metalId: "" }));
    }
    if (!shapValue?.unAttributeValueIdentifier) {
      setErrorValidation((prev) => ({
        ...prev,
        shapeValue: "Shape Data is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, shapeValue: "" }));
    }
    if (editorValue === "") {
      // setErrorValidation((prev) => ({
      //   ...prev,
      //   editorValue: "Long Description is Requied!",
      // }));
    } else {
      setErrorValidation((prev) => ({ ...prev, editorValue: "" }));
    }
    if (designData.dcSalePrice === "" || designData.dcSalePrice === 0) {
      setErrorValidation((prev) => ({
        ...prev,
        dcSalePrice: "Sale Price is Requied!",
      }));
    } else {
      setErrorValidation((prev) => ({ ...prev, dcSalePrice: "" }));
    }
  };

  const handleSave = async () => {
    try {
      var arr = [];
      Object.entries(designData.attribute).forEach((item) => {
        const data = {
          AttributeIdentifier: item[0],
          AttributeValueIdentifier: item[1].id,
        };
        arr.push(data);
      });
      const imgArr = [];
      // imageData.length > 0 &&
      //   imageData.map((item, i) => {
      //     const data = {
      //       SrNumber: i + 1,
      //       ImageURL: item,
      //       VideoURL: "",
      //     };
      //     imgArr.push(data);
      //   });

      const sideStone =
        sideStoneDetails?.length > 0
          ? sideStoneDetails?.filter((item) => item.Carat !== null)
          : [];
      const mainStone =
        mainStoneDetails?.length > 0
          ? mainStoneDetails?.filter((item) => item.Carat !== null)
          : [];

      const StockDetails = [...mainStone, ...sideStone];
      // mainStone.length > 0 ? mainStone?.concat(sideStone) : [];

      if (id) {
        const payload = {
          StockID: id,
          DesignId: designData.stDesign,
          StockName: designData.stDesignName,
          DefImageURL: designData.stDefImageURL,
          FocusImageURL: designData.stFocusImageURL,
          DesignRemark: designData.stRemark1,
          AttributeValueMetalId: metalId.unAttributeValueIdentifier,
          AttributeValueShapeId: shapValue.unAttributeValueIdentifier,
          DesignLongRemark: editorValue,
          StockDetails: JSON.stringify(StockDetails),
          NetWt: designData.dcNtWt,
          GrossWt: designData.dcGrWt,
          TotalCost: designData.dcTotalCost || 0,
          SalePrice: designData.dcSalePrice,
          Attribute: JSON.stringify(arr),
          StockNo: designData.StockNo,
          StockImages: JSON.stringify(imageData),
          IsSale: designData.flgIsActive,
          CategoryId: selected?.unCategoryIdentifier,
          CategoryStyleId: categoryStyle?.unCategoryStyleIdentifier,
          IsNew: designData?.flgIsNew,
          IsHotStock: designData?.flgIsHotDesign,
          Tags: JSON.stringify(tagAuto.map((item) => item.id)),
        };

        const res = await updateReadyToShipDesign(payload);
        if (res.status === 200) {
          toast.success(res.data.message);
          if (isSubmit) {
            return;
          } else {
            navigate("/admin/readytoship");
            setMainStoneDetails([mainStoneDef]);
            setSideStoneDetails([mainStoneDef]);
            setDesignData(initialDesignData);
            setIsSubmit(false);
            setFileImage([]);
            setAllImages([]);
          }
        } else {
          toast.error(res.data.message);
        }
      } else {
        if (designData.stDesignName &&
          designData.StockNo &&
          designData.stDefImageURL &&
          designData.stFocusImageURL &&
          designData.stRemark1 &&
          metalId.unAttributeValueIdentifier &&
          shapValue.unAttributeValueIdentifier &&
          // editorValue &&
          designData.dcSalePrice &&
          imageData.length > 0
        ) {
          const payload = new FormData();
          payload.append("DesignId", designData.stDesign);
          payload.append("StockName", designData.stDesignName);
          payload.append("DefImageURL", designData.stDefImageURL);
          payload.append("FocusImageURL", designData.stFocusImageURL);
          payload.append("DesignRemark", designData.stRemark1);
          payload.append(
            "AttributeValueMetalId",
            metalId.unAttributeValueIdentifier
          );
          payload.append(
            "AttributeValueShapeId",
            shapValue.unAttributeValueIdentifier
          );
          payload.append("DesignLongRemark", editorValue);
          payload.append("StockDetails", JSON.stringify(StockDetails));
          payload.append("NetWt", designData.dcNtWt);
          payload.append("GrossWt", designData.dcGrWt);
          payload.append("TotalCost", designData.dcTotalCost || 0);
          payload.append("SalePrice", designData.dcSalePrice);
          payload.append("Attribute", JSON.stringify(arr));
          // payload.append("StockProps", []);
          payload.append("StockNo", designData.StockNo);
          payload.append("StockImages", JSON.stringify(imageData));
          payload.append("IsSale", designData.flgIsActive);
          payload.append("CategoryId", selected?.unCategoryIdentifier);
          payload.append(
            "CategoryStyleId",
            categoryStyle?.unCategoryStyleIdentifier
          );
          payload.append("IsNew", designData.flgIsNew);
          payload.append("IsHotStock", designData.flgIsHotDesign);
          payload.append(
            "Tags",
            JSON.stringify(tagAuto.map((item) => item.id))
          );
          const res = await postReadyToShipDesign(payload);
          if (res.status === 200) {
            toast.success(res.data.message);
            if (isSubmit) {
              return;
            } else {
              navigate("/admin/readytoship");
              setMainStoneDetails([mainStoneDef]);
              setSideStoneDetails([mainStoneDef]);
              setDesignData(initialDesignData);
              setIsSubmit(false);
              setFileImage([]);
              setAllImages([]);
            }
          } else {
            toast.error(res.data.message);
          }
        } else {
          validation();
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleCancel = () => {
    setMainStoneDetails(mainStoneDef);
    setSideStoneDetails(mainStoneDef);
    setDesignData(initialDesignData);
    navigate("/admin/readytoship");
  };

  useEffect(() => {
    if (isCancel === true) {
      handleCancel();
    }
  }, [isCancel]);

  useEffect(() => {
    if (basicInfo) {
      handleSave();
      setBasicInfo(false);
    }
  }, [basicInfo]);

  const handleClickRowAdd = () => {
    setMainStoneDetails((prev) =>
      prev.length > 0 ? [...prev, mainStoneDef] : [mainStoneDef]
    );
  };

  const tagOptions = useMemo(() => {
    return tag.map((item) => ({
      title: item.stTagName,
      id: item.unTagIdentifier,
    }));
  }, [tag]);

  const handleChangeMainStone = (e, nam, index, type) => {
    if (type === "main") {
      if (nam) {
        setMainStoneDetails((prev) => {
          return prev.map((item, i) => {
            if (i === index) {
              if (nam === "Shape") {
                return { ...item, [nam]: e, StockJvType: type };
              } else {
                return { ...item, [nam]: e };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        const { name, value } = e.target;
        setMainStoneDetails((prev) => {
          return prev.map((item, i) => {
            const rate = name === "Rate" ? value : item.Rate;
            const qty = name === "Carat" ? value : item.Carat;
            const Amount = +rate * +qty;
            const totalAmount = isNaN(Math.round(Amount))
              ? 0
              : Math.round(Amount);
            if (i === index) {
              return {
                ...item,
                [name]: value,
                Amount: totalAmount,
                StockJvType: type,
              };
            } else {
              return item;
            }
          });
        });
      }
    } else {
      if (nam) {
        setSideStoneDetails((prev) => {
          return prev.map((item, i) => {
            if (i === index) {
              if (nam === "Shape") {
                return { ...item, [nam]: e, StockJvType: type };
              } else {
                return { ...item, [nam]: e };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        const { name, value } = e.target;
        setSideStoneDetails((prev) => {
          return prev.map((item, i) => {
            const rate = name === "Rate" ? value : item.Rate;
            const qty = name === "Carat" ? value : item.Carat;
            const Amount = +rate * +qty;
            const totalAmount = isNaN(Math.round(Amount))
              ? 0
              : Math.round(Amount);
            if (i === index) {
              return {
                ...item,
                [name]: value,
                Amount: totalAmount,
                StockJvType: type,
              };
            } else {
              return item;
            }
          });
        });
      }
    }
  };

  const handleDeleteRow = (id) => {
    const filterData = mainStoneDetails.filter((item, i) => i !== id);
    setMainStoneDetails(filterData);
  };

  const handleClickSideStoneRowAdd = () => {
    setSideStoneDetails((prev) =>
      prev.length > 0 ? [...prev, mainStoneDef] : [mainStoneDef]
    );
  };

  const handleDeleteSideStoneRow = (id) => {
    const filterData = sideStoneDetails.filter((item, i) => i !== id);
    setSideStoneDetails(filterData);
  };

  const getAttributesValueData = async () => {
    const newValues = {};
    attributeOptions.length > 0 &&
      attributeOptions.forEach(async (item, i) => {
        const response = await getAttributeValueByAttribute(item.id);
        if (response.status === 200) {
          const values = response.data.data.attribute_value?.map((item) => {
            return {
              id: item.unAttributeValueIdentifier,
              title: item.stAttributeValueName,
            };
          });
          setAttributeValueData((prev) => ({ ...prev, [item.title]: values }));
          // newValues[item.title] = values;
        }
      });
  };

  const handleClear = (id) => {
    const attData = { ...designData?.attribute };
    delete attData[id];
    setDesignData((prev) => ({ ...prev, attribute: attData }));
  };

  useEffect(() => {
    if (attributeOptions.length > 0) {
      getAttributesValueData();
    }
  }, [attributeOptions]);
  if (designLoading) {
    return (
      <div className="w-full h-[400px] flex justify-center items-center">
        <img src={Loading} alt="loading" className="w-[40px] h-[40px]" />
      </div>
    );
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Basic Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
            <div className="col-span-3">
              <label
                htmlFor="stDesignAlias"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Design Number
                <span className="text-red-500">*</span>
              </label>

              {/* <TextInputAdmin
                type="text"
                value={designData.stDesignAlias}
                onChange={handleChange}
                name="stDesignAlias"
              />  */}

              {/* <SelectMenu
                data={designData.stDesign}
                //options={designOption}
                setData={(e) => handleChange(e, "stDesign")}
                className={"bg-white py-[4px] h-[36px] border-gray-300"}
              /> */}

              <TextInputAdmin
                type="text"
                value={designData.stDesign}
                onChange={handleChange}
                name="stDesign"
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="stDesignAlias"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Stock No
                <span className="text-red-500">*</span>
              </label>
              <TextInputAdmin
                type="text"
                value={designData.StockNo}
                onChange={handleChange}
                name="StockNo"
              />
              {errorValidation.StockNo !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.StockNo}
                </p>
              )}
            </div>
            <div className="sm:col-span-full col-span-3">
              <label
                htmlFor="stDesignName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
                <span className="text-red-500">*</span>
              </label>
              <TextInputAdmin
                type="text"
                value={designData.stDesignName}
                onChange={handleChange}
                name="stDesignName"
              />
              {errorValidation.stDesignName !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.stDesignName}
                </p>
              )}
            </div>
            <div className="col-span-3">
              <div className="">
                <div>
                  <label
                    htmlFor="stDefImageURL"
                    className="block text-sm font-medium leading-0 text-gray-900"
                  >
                    Default Image
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center gap-x-3">
                    <PhotoIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <label
                      htmlFor="stDefImageURL"
                      className="rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Change
                    </label>
                    {isLoading && (
                      <img
                        src={Loading}
                        alt="loading"
                        className="w-[40px] h-[40px]"
                      />
                    )}
                  </div>
                  <ImageUpload
                    id="stDefImageURL"
                    onChange={(e) => handleDefaultImage(e)}
                    className="rounded-md hidden bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  />
                </div>
                {errorValidation.stDefImageURL !== "" && (
                  <p className="text-red-600 text-[12px]">
                    {errorValidation.stDefImageURL}
                  </p>
                )}
              </div>
              <div className="">
                <div className="border border-dashed border-[#94a3b8] w-[200px] p-2 rounded">
                  {designData.stDefImageURL ? (
                    <img
                      src={designData.stDefImageURL}
                      alt="defaultImage"
                      className="w-[200px] h-[200px] mx-auto"
                    />
                  ) : (
                    <div className="text-[#94a3b8] text-[14px] font-Poppins h-[200px] flex items-center justify-center">
                      Preview
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="">
                <label
                  htmlFor="stFocusImageURL"
                  className="block text-sm font-medium leading-0 text-gray-900"
                >
                  Hover Image
                  <span className="text-red-500">*</span>
                </label>
                <div className="flex items-center gap-x-3">
                  <PhotoIcon
                    className="h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <label
                    htmlFor="stFocusImageURL"
                    className="rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </label>
                  {loading && (
                    <img
                      src={Loading}
                      alt="loading"
                      className="w-[40px] h-[40px]"
                    />
                  )}
                </div>
                <ImageUpload
                  id="stFocusImageURL"
                  onChange={(e) => handleImage(e)}
                  className="rounded-md hidden bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                />
              </div>
              {errorValidation.stFocusImageURL !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.stFocusImageURL}
                </p>
              )}
              <div className="">
                <div className="border border-dashed border-[#94a3b8] w-[200px] p-2 rounded">
                  {designData.stFocusImageURL ? (
                    <img
                      src={designData.stFocusImageURL}
                      alt="hover"
                      className="w-[200px] h-[200px]"
                    />
                  ) : (
                    <div className="text-[#94a3b8] text-[14px] font-Poppins h-[200px] flex items-center justify-center">
                      Preview
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* category */}
            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Category
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={selected}
                onChange={(val) => {
                  handleSetData(val, "category");
                  // setCategoryStyleData([]);
                  // setCategoryStyle(null);
                }}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {selected ? selected?.stCategoryName : "Select Category"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {category?.length &&
                        category?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stCategoryName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {errorValidation?.unCategoryId !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation?.unCategoryId}
                </p>
              )}
            </div>
            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Style
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={categoryStyle}
                onChange={(val) => handleSetData(val, "categoryStyle")}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate">
                      {categoryStyle
                        ? categoryStyle?.stCategoryStyleName
                        : "Select Category Style"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {categoryStyleData?.length ? (
                        categoryStyleData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stCategoryStyleName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))
                      ) : (
                        <p className="text-[12px] text-gray-400 text-center">
                          First Select Category
                        </p>
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {errorValidation?.unCategoryStyleId !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation?.unCategoryStyleId}
                </p>
              )}
            </div>

            <div className="sm:col-span-full col-span-3">
              <label
                htmlFor="stRemark1"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Design Short Description
              </label>
              <div>
                <textarea
                  id="stRemark1"
                  name="stRemark1"
                  rows={3}
                  onChange={handleChange}
                  value={designData?.stRemark1}
                  className="block w-full outline-0 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:p-1.5 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
                {errorValidation.stRemark1 !== "" && (
                  <p className="text-red-600 text-[12px]">
                    {errorValidation.stRemark1}
                  </p>
                )}
              </div>
            </div>

            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Metal Id
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={metalId}
                onChange={(val) => handleSetData(val, "metalId")}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate text-[14px]">
                      {metalId ? metalId?.stAttributeValueName : "Select Metal"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {metalData?.length &&
                        metalData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stAttributeValueName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {errorValidation.metalId !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.metalId}
                </p>
              )}
            </div>
            <div className="col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Main Stone Shape
                <span className="text-red-500">*</span>
              </label>
              <Listbox
                value={shapValue}
                onChange={(val) => handleSetData(val, "shapeValue")}
              >
                <div className="relative">
                  <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-[#D1D5DB] focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-0">
                    <span className="block truncate">
                      {shapValue
                        ? shapValue?.stAttributeValueName
                        : "Select Center Stone"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {shapeData?.length &&
                        shapeData?.map((person, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.stAttributeValueName}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              {errorValidation.shapeValue !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.shapeValue}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Multiple Design Images
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Side Multiple Images, Video
            </p>
          </div>

          <div className="col-span-2">
            <div className="mb-3">
              <span className="mb-2 inline-block text-neutral-700 dark:text-neutral-200">
                Images
                <span className="text-red-500">*</span>
              </span>
              <div className="flex items-center gap-[20px]">
                <div className="shadow rounded-lg">
                  <label
                    htmlFor="formFile"
                    className="border rounded-l-lg px-3 py-[9px] text-[14px] bg-[#fff]"
                  >
                    Choose image
                  </label>
                  <input
                    type="text"
                    disabled
                    className="border rounded-r-lg py-1.5 px-2 bg-[#fff]"
                    value={
                      !allImages?.length
                        ? "No Images"
                        : allImages?.length + " " + "Files"
                    }
                  />
                </div>

                <button
                  className="border rounded px-3 py-2 text-[13px] bg-white"
                  onClick={handaleImageChanges}
                >
                  Upload
                </button>

                {isLoading && (
                  <img
                    src={Loading}
                    alt="loading"
                    className="w-[40px] h-[40px]"
                  />
                )}
                {uploadImage && (
                  <BsCheckCircleFill className="text-green-600" />
                )}
              </div>
              <ImageUpload
                className=" hidden relative m-0 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                id="formFile"
                onChange={(e) => {
                  handleFile(e);
                }}
                multiple
                video
              />
              {errorValidation.imageData !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.imageData}
                </p>
              )}
              <div className="flex flex-wrap gap-2 mt-2">
                {filesImage.map((file, key) => {
                  const findVideo = allImages.findIndex(
                    (item) => item?.img?.type === "video/mp4"
                  );
                  if (
                    findVideo === key ||
                    file?.img?.endsWith("mp4") ||
                    file?.img?.endsWith("mov")
                  ) {
                    return (
                      <div key={key} className="overflow-hidden relative">
                        <GrFormClose
                          onClick={() => {
                            removeImage(key);
                          }}
                          className="absolute right-1 text-white cursor-pointer"
                        />
                        <div className="h-20 w-20 rounded-md bg-white flex items-center justify-center">
                          <CgPlayButtonO className="text-[40px] w-[30px] h-[30px]" />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={key} className="overflow-hidden relative">
                        <GrFormClose
                          onClick={() => {
                            removeImage(key);
                          }}
                          className="absolute right-1 text-white cursor-pointer"
                        />
                        <img
                          className="h-20 w-20 rounded-md"
                          src={file.img}
                          alt={`data-${key}`}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Long Description
              {/* <span className="text-red-500">*</span> */}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Long Details Description
            </p>
          </div>

          <div className="max-w-2xl md:col-span-2">
            <CKEditor
              editor={ClassicEditor}
              data={editorValue}
              // value={designData?.des}
              onChange={handleChangeEditor}
            />
            {errorValidation.editorValue !== "" && (
              <p className="text-red-600 text-[12px]">
                {errorValidation.editorValue}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-4 gap-x-4 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div className="flex items-center justify-between col-span-full">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Main Stone Details
            </h2>
            {/* <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Metal Gross, Net and Wax Weight Information
            </p> */}
            <button
              type="button"
              onClick={handleClickRowAdd}
              className="bg-white px-[13px] pt-2 pb-[11px] text-[18px] shadow leading-[10px] rounded text-slate-800"
            >
              +
            </button>
          </div>

          <div className="md:col-span-full">
            <table className="w-full border">
              <thead>
                <tr>
                  <th className="w-[150px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Shape
                  </th>
                  <th className="w-[103px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Size/Sieve
                  </th>
                  <th className="w-[61px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Color
                  </th>
                  <th className="w-[91px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Clarity
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Pcs
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Carat
                    <span className="text-red-500">*</span>
                  </th>
                  <th className="w-[73px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Rate
                  </th>
                  <th className="w-[88px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Amount
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Lab
                  </th>
                  <th className="w-[150px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Certi No.
                  </th>
                  <th className="w-[80px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Stone No.
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Depth
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Length
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Width
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[12px] font-semibold">
                    Remark
                  </th>
                  <th className="border-b w-[39px]"></th>
                </tr>
              </thead>
              <tbody>
                {mainStoneDetails?.length > 0 &&
                  mainStoneDetails.map((item, i) => {
                    return (
                      <tr>
                        <td className="w-[150px]">
                          <SelectMenuTableView
                            data={item.Shape}
                            options={ShapeOption}
                            setData={(e) =>
                              handleChangeMainStone(e, "Shape", i, "main")
                            }
                          />
                          {/* <input
                            type="text"
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Shape"
                          /> */}
                        </td>
                        <td>
                          <SelectMenuTableView
                            data={item.Sieve}
                            options={sizeSelect(item.Shape)}
                            setData={(e) =>
                              handleChangeMainStone(e, "Sieve", i, "main")
                            }
                          />
                        </td>
                        <td className="w-[61px]">
                          <SelectMenuTableView
                            data={item.Colour}
                            options={colorOption}
                            setData={(e) =>
                              handleChangeMainStone(e, "Colour", i, "main")
                            }
                          />
                          {/* <input
                            type="text"
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Color"
                          /> */}
                        </td>
                        <td className="w-[91px]">
                          <SelectMenuTableView
                            data={item.Clarity}
                            options={clarityOption}
                            setData={(e) =>
                              handleChangeMainStone(e, "Clarity", i, "main")
                            }
                          />
                          {/* <input
                            type="text"
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Clarity"
                          /> */}
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Pcs"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Pcs}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Pcs"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Carat"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Carat}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Carat"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Rate"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Rate}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Rate"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Amount"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Amount}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Amount"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="LAB"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.LAB}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Lab"
                          />
                        </td>
                        <td className="w-[150px]">
                          <input
                            type="text"
                            name="CertiId"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.CertiId}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Certi No."
                          />
                        </td>
                        <td className="w-[80px]">
                          <input
                            type="text"
                            name="StoneNo"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.StoneNo}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Stone No."
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Depth"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Depth}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Depth"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Length"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Length}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Length"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Width"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Width}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Width"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Remark"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "main")
                            }
                            value={item.Remark === "[]" ? "" : item.Remark}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Remark"
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleDeleteRow(i)}
                            className="bg-red-200 text-red-700 p-2 text-[16px] rounded"
                          >
                            <MdOutlineDeleteOutline />{" "}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-4 gap-x-4 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div className="flex items-center justify-between col-span-full">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Side Stone Details
            </h2>
            {/* <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Metal Gross, Net and Wax Weight Information
            </p> */}
            <button
              type="button"
              onClick={handleClickSideStoneRowAdd}
              className="bg-white px-[13px] pt-2 pb-[11px] text-[18px] shadow leading-[10px] rounded text-slate-800"
            >
              +
            </button>
          </div>

          <div className="md:col-span-full">
            <table className="w-full border">
              <thead>
                <tr>
                  <th className="w-[150px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Shape
                  </th>
                  <th className="w-[103px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Size/Sieve
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Color
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Clarity
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Pcs
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Carat
                    <span className="text-red-500">*</span>
                  </th>
                  <th className="w-[73px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Rate
                  </th>
                  <th className="w-[88px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Amount
                  </th>

                  <th className="w-[150px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Setting Type
                  </th>
                  <th className="w-[80px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Stone No.
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Depth
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Length
                  </th>
                  <th className="w-[40px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Width
                  </th>
                  <th className="w-[83px] border-r border-b text-left py-1.5 px-2 text-[13px] font-semibold">
                    Remark
                  </th>
                  <th className="border-b w-[39px]"></th>
                </tr>
              </thead>
              <tbody>
                {sideStoneDetails.length > 0 &&
                  sideStoneDetails.map((item, i) => {
                    return (
                      <tr>
                        <td className="w-[150px]">
                          <SelectMenuTableView
                            data={item.Shape}
                            options={ShapeOption}
                            setData={(e) =>
                              handleChangeMainStone(e, "Shape", i, "side")
                            }
                          />
                          {/* <input
                            type="text"
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Shape"
                          /> */}
                        </td>
                        <td>
                          <SelectMenuTableView
                            data={item.Sieve}
                            options={sizeSelect(item.Shape)}
                            setData={(e) =>
                              handleChangeMainStone(e, "Sieve", i, "side")
                            }
                          />
                        </td>
                        <td className="w-[61px]">
                          <SelectMenuTableView
                            data={item.Colour}
                            options={colorOption}
                            setData={(e) =>
                              handleChangeMainStone(e, "Colour", i, "side")
                            }
                          />
                          {/* <input
                            type="text"
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Color"
                          /> */}
                        </td>
                        <td className="w-[91px]">
                          <SelectMenuTableView
                            data={item.Clarity}
                            options={clarityOption}
                            setData={(e) =>
                              handleChangeMainStone(e, "Clarity", i, "side")
                            }
                          />
                          {/* <input
                            type="text"
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Clarity"
                          /> */}
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Pcs"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Pcs}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Pcs"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Carat"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Carat}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Carat"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Rate"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Rate}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Rate"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Amount"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Amount}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Amount"
                          />
                        </td>

                        <td className="w-[150px]">
                          {/* <input
                            type="text"
                            name="CertiId"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.CertiId}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Certi No."
                          /> */}
                          <SelectMenuTableView
                            data={item.CertiId}
                            options={settingTypeOptions}
                            setData={(e) =>
                              handleChangeMainStone(e, "CertiId", i, "side")
                            }
                          />
                        </td>
                        <td className="w-[80px]">
                          <input
                            type="text"
                            name="StoneNo"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.StoneNo}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Stone No."
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Depth"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Depth}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Depth"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Length"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Length}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Length"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Width"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Width}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Width"
                          />
                        </td>
                        <td className="w-[40px]">
                          <input
                            type="text"
                            name="Remark"
                            onChange={(e) =>
                              handleChangeMainStone(e, "", i, "side")
                            }
                            value={item.Remark === "[]" ? "" : item.Remark}
                            className="w-full py-1.5 shadow-sm px-2 text-[13px]"
                            placeholder="Remark"
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleDeleteSideStoneRow(i)}
                            className="bg-red-200 text-red-700 p-2 text-[16px] rounded"
                          >
                            <MdOutlineDeleteOutline />{" "}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Design Weight
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Design Metal Gross, Net and Wax Weight Information
              <br />
              Sell price = All Other Expenses With Margin
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-8 md:col-span-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="dcNtWt"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Net Weight (Metal)
              </label>
              <TextInputAdmin
                type="text"
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcNtWt"
                value={designData?.dcNtWt}
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="dcGrWt"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Gross Weight (Jewelry)
              </label>
              <TextInputAdmin
                type="text"
                value={designData?.dcGrWt}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcGrWt"
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="dcTotalCost"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Total Cost
              </label>
              <TextInputAdmin
                type="text"
                value={designData.dcTotalCost}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                name="dcTotalCost"
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="dcSalePrice"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Sell Price (Except Metal)
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="dcSalePrice"
                value={designData.dcSalePrice}
                onChange={(e) => {
                  const { value } = e.target;
                  if (numberRegexWithDecimals.test(value)) {
                    handleChange(e);
                  }
                }}
                className="block w-full outline-0	 bg-[#f1f5f9] rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#64748b] placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
              {errorValidation.dcSalePrice !== "" && (
                <p className="text-red-600 text-[12px]">
                  {errorValidation.dcSalePrice}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-10 gap-x-8 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Attribute
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Add Design Attribute for Create Multiple Variation
            </p>
          </div>

          <div className="grid gap-y-3  md:col-span-2">
            <table className="w-full text-sm text-left text-gray-500 mb-4 dark:text-gray-400">
              <thead className="text-xs text-gray-500 uppercase bg-[#e2e8f0] dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4 w-[200px]">
                    Attribute
                  </th>
                  <th scope="col" className="px-6 py-4 text-center">
                    Attribute Value
                  </th>
                  <th className="w-[30px]"></th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  attributeOptions?.length &&
                  attributeOptions.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="bg-white border-b dark:border-gray-700 dark:odd:bg-gray-900 dark:even:bg-gray-800"
                      >
                        <th
                          scope="row"
                          className="px-6 py-2 font-medium text-left text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.title}
                        </th>
                        <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <SelectMenu
                            data={designData?.attribute?.[item.id] || null}
                            setData={(e) => handleChangeAttribute(item.id, e)}
                            options={attributeValueData[item.title]}
                            widthClass={"w-3/4 mx-auto"}
                            dropDownHeightClass="max-h-[150px] h-auto"
                          />
                        </td>
                        <td className="px-6">
                          <button
                            type="button"
                            className="bg-slate-200 px-4 py-2 rounded"
                            onClick={() => handleClear(item.id)}
                          >
                            Clear
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* {attributeOptions.length > 0 &&
              attributeOptions.map((item, i) => {
                return (
                  <div className="col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      {item.title}
                    </label>
                    <SelectMenu
                      data={accountant}
                      setData={setAccountant}
                      options={attributeValueData[item.title]}
                      widthClass={"w-3/4"}
                      dropDownHeightClass="max-h-[150px] h-auto"
                    />
                  </div>
                );
              })} */}
            <div className="sm:col-span-full col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Tag
              </label>

              {/* <input type="text" name='attribute' id='attribute' className='block h-[40px] rounded-[6px] w-full outline-none border-[1px] border-[##D1D5DB] px-[13px] ' /> */}
              <AutoComplete
                onChange={(data) => setTagAuto(data)}
                defValue={tagAuto}
                options={tagOptions}
              />
            </div>
            <div className="my-1 col-span-full">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  name="flgIsHotDesign"
                  checked={!!Number(designData.flgIsHotDesign)}
                  onChange={handleChange}
                  id="flgIsNew"
                  className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
                />
                <label
                  htmlFor="flgIsHot"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is Hot Design (Hot Selling)
                </label>
              </div>
            </div>
            <div className="my-1 col-span-full">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  name="flgIsNew"
                  checked={!!Number(designData.flgIsNew)}
                  onChange={handleChange}
                  id="flgIsNew"
                  className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
                />
                <label
                  htmlFor="flgIsNew"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is New
                </label>
              </div>
            </div>
            <div className="col-span-full">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  name="flgIsActive"
                  checked={!!Number(designData.flgIsActive)}
                  onChange={handleChange}
                  id="flgIsActive"
                  className="w-4 h-4 border-gray-300 rounded accent-[#334155]"
                />
                <label
                  htmlFor="flgIsActive"
                  className=" text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  is Active
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
